@font-face {
  font-family: 'jnicon';
  src:
    url('fonts/jnicon.ttf?n0bdkv') format('truetype'),
    url('fonts/jnicon.woff?n0bdkv') format('woff'),
    url('fonts/jnicon.svg?n0bdkv#jnicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jnicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reportfile:before {
  content: "\e90b";
}
.icon-att-report:before {
  content: "\eac8";
}
.icon-finance-report:before {
  content: "\eade";
}
.icon-pay:before {
  content: "\eae4";
}
.icon-circle:before {
  content: "\eae5";
}
.icon-support:before {
  content: "\eac9";
  color: #5082ba;
}
.icon-permission:before {
  content: "\eaca";
}
.icon-attendance:before {
  content: "\eacb";
}
.icon-admision:before {
  content: "\eae6";
}
.icon-organization:before {
  content: "\eae7";
}
.icon-box-star:before {
  content: "\eacc";
}
.icon-certificate2:before {
  content: "\eacd";
}
.icon-chart:before {
  content: "\eace";
}
.icon-clothes:before {
  content: "\eacf";
}
.icon-connection:before {
  content: "\ead0";
}
.icon-customer:before {
  content: "\ead1";
}
.icon-desk:before {
  content: "\ead2";
}
.icon-handcuffs:before {
  content: "\ead3";
}
.icon-handshake:before {
  content: "\ead4";
}
.icon-history:before {
  content: "\ead5";
}
.icon-location:before {
  content: "\ead6";
}
.icon-meal:before {
  content: "\ead7";
}
.icon-money-bag:before {
  content: "\ead8";
}
.icon-plants:before {
  content: "\ead9";
}
.icon-point:before {
  content: "\eada";
}
.icon-restroom:before {
  content: "\eadb";
}
.icon-ribbon:before {
  content: "\eadc";
}
.icon-vest:before {
  content: "\eae0";
}
.icon-appid:before {
  content: "\ea9e";
}
.icon-basket:before {
  content: "\ea9d";
}
.icon-bed:before {
  content: "\ea9c";
}
.icon-biometric:before {
  content: "\e98f";
}
.icon-books-h:before {
  content: "\ea9f";
}
.icon-contract:before {
  content: "\eaa0";
}
.icon-presentation:before {
  content: "\eaa1";
}
.icon-room-service:before {
  content: "\eaa2";
}
.icon-suitcase:before {
  content: "\eaa3";
}
.icon-tea:before {
  content: "\eac7";
}
.icon-audio:before {
  content: "\e97c";
}
.icon-audio-off:before {
  content: "\e97d";
}
.icon-delete:before {
  content: "\e97e";
}
.icon-disabled-user:before {
  content: "\e981";
}
.icon-disabled-users:before {
  content: "\e982";
}
.icon-user-admin:before {
  content: "\e983";
}
.icon-verified:before {
  content: "\e984";
  color: #0082c9;
}
.icon-verify:before {
  content: "\e98b";
  color: #969696;
}
.icon-verifying:before {
  content: "\e98c";
  color: #0082c9;
}
.icon-cart-empty .path1:before {
  content: "\e9a5";
  color: rgb(0, 150, 136);
}
.icon-cart-empty .path2:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-empty .path3:before {
  content: "\eae9";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-empty .path4:before {
  content: "\eaea";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-empty .path5:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-empty .path6:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-empty .path7:before {
  content: "\eaed";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-add .path1:before {
  content: "\e9a7";
  color: rgb(0, 150, 136);
}
.icon-cart-add .path2:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-add .path3:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-add .path4:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-add .path5:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-add .path6:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-add .path7:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-add .path8:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}
.icon-cart-add .path9:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}
.icon-cart-buying .path1:before {
  content: "\e9b2";
  color: rgb(245, 124, 0);
}
.icon-cart-buying .path2:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}
.icon-cart-buying .path3:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-buying .path4:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-buying .path5:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-buying .path6:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-buying .path7:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-buying .path8:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-buying .path9:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-checkout .path1:before {
  content: "\e9c0";
  color: rgb(63, 81, 181);
}
.icon-cart-checkout .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-checkout .path3:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-checkout .path4:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-checkout .path5:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-checkout .path6:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-checkout .path7:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-checkout .path8:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-return .path1:before {
  content: "\e9d0";
  color: rgb(0, 150, 136);
}
.icon-cart-return .path2:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-return .path3:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-return .path4:before {
  content: "\eaf0";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-return .path5:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-return .path6:before {
  content: "\eaf2";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-return .path7:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-return .path8:before {
  content: "\eaf4";
  margin-left: -1em;
  color: rgb(170, 0, 255);
}
.icon-cart-sell .path1:before {
  content: "\e9dd";
  color: rgb(55, 71, 79);
}
.icon-cart-sell .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-sell .path3:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-sell .path4:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-sell .path5:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-sell .path6:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-sell .path7:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-sell .path8:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}
.icon-cart-sell .path9:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}
.icon-cart-sell-history .path1:before {
  content: "\e9e6";
  color: rgb(245, 124, 0);
}
.icon-cart-sell-history .path2:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}
.icon-cart-sell-history .path3:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-sell-history .path4:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-cart-sell-history .path5:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}
.icon-cart-sell-history .path6:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-sell-history .path7:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-cart-sell-history .path8:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-sell-history .path9:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-cart-sell-history .path10:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(0, 172, 193);
}
.icon-cart-sell-history .path11:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}
.icon-cart-sell-history .path12:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clientes:before {
  content: "\ea7d";
}
.icon-contrato .path1:before {
  content: "\ea85";
  color: rgb(144, 202, 249);
}
.icon-contrato .path2:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(225, 245, 254);
}
.icon-contrato .path3:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-contrato .path4:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-contrato .path5:before {
  content: "\eb0b";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-contrato .path6:before {
  content: "\eb0c";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-contrato .path7:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-exchange .path1:before {
  content: "\ea86";
  color: rgb(189, 189, 189);
}
.icon-exchange .path2:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(189, 189, 189);
}
.icon-exchange .path3:before {
  content: "\eb0f";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}
.icon-exchange .path4:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}
.icon-exchange .path5:before {
  content: "\eb11";
  margin-left: -1em;
  color: rgb(46, 125, 50);
}
.icon-email .path1:before {
  content: "\ea87";
  color: rgb(21, 101, 192);
}
.icon-email .path2:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-faq .path1:before {
  content: "\ea88";
  color: rgb(85, 139, 47);
}
.icon-faq .path2:before {
  content: "\eb13";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-faq .path3:before {
  content: "\eb14";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}
.icon-faq .path4:before {
  content: "\eb15";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-guru .path1:before {
  content: "\ea89";
  color: rgb(255, 183, 77);
}
.icon-guru .path2:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(255, 117, 76);
}
.icon-guru .path3:before {
  content: "\eb17";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}
.icon-guru .path4:before {
  content: "\eb18";
  margin-left: -1em;
  color: rgb(255, 117, 76);
}
.icon-guru .path5:before {
  content: "\eb19";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}
.icon-guru .path6:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}
.icon-guru .path7:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}
.icon-guru .path8:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}
.icon-id-card .path1:before {
  content: "\ea8a";
  color: rgb(74, 117, 195);
}
.icon-id-card .path2:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(44, 66, 144);
}
.icon-id-card .path3:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(255, 214, 82);
}
.icon-id-card .path4:before {
  content: "\eb21";
  margin-left: -1em;
  color: rgb(251, 226, 135);
}
.icon-id-card .path5:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(178, 178, 178);
}
.icon-id-card .path6:before {
  content: "\eb23";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-id-card .path7:before {
  content: "\eb24";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}
.icon-id-card .path8:before {
  content: "\eb25";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-id-card .path9:before {
  content: "\eb26";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-id-card .path10:before {
  content: "\eb27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-id-card .path11:before {
  content: "\eb28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-id-card .path12:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}
.icon-id-card .path13:before {
  content: "\eb2a";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}
.icon-id-card .path14:before {
  content: "\eb2b";
  margin-left: -1em;
  color: rgb(77, 104, 181);
}
.icon-id-card .path15:before {
  content: "\eb2c";
  margin-left: -1em;
  color: rgb(74, 117, 195);
}
.icon-id-card .path16:before {
  content: "\eb2d";
  margin-left: -1em;
  color: rgb(69, 143, 222);
}
.icon-id-card .path17:before {
  content: "\eb2e";
  margin-left: -1em;
  color: rgb(111, 174, 232);
}
.icon-id-card .path18:before {
  content: "\eb2f";
  margin-left: -1em;
  color: rgb(69, 143, 222);
}
.icon-id-card .path19:before {
  content: "\eb30";
  margin-left: -1em;
  color: rgb(111, 174, 232);
}
.icon-seller:before {
  content: "\ea8d";
}
.icon-storehouse .path1:before {
  content: "\ea8f";
  color: rgb(255, 152, 0);
}
.icon-storehouse .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(212, 126, 0);
}
.icon-storehouse .path3:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(212, 126, 0);
}
.icon-storehouse .path4:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}
.icon-storehouse .path5:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}
.icon-storehouse .path6:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}
.icon-storehouse .path7:before {
  content: "\ea97";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}
.icon-storehouse .path8:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}
.icon-usergroup .path1:before {
  content: "\ea9b";
  color: rgb(139, 195, 74);
}
.icon-usergroup .path2:before {
  content: "\eb36";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}
.icon-usergroup .path3:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(255, 167, 38);
}
.icon-usergroup .path4:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(255, 167, 38);
}
.icon-usergroup .path5:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(51, 105, 30);
}
.icon-usergroup .path6:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(51, 105, 30);
}
.icon-usergroup .path7:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}
.icon-usergroup .path8:before {
  content: "\eb3c";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}
.icon-usergroup .path9:before {
  content: "\eb3d";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}
.icon-usergroup .path10:before {
  content: "\eb3e";
  margin-left: -1em;
  color: rgb(104, 159, 56);
}
.icon-warehouse .path1:before {
  content: "\eaa4";
  color: rgb(207, 158, 118);
}
.icon-warehouse .path2:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(211, 160, 108);
}
.icon-warehouse .path3:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(230, 233, 237);
}
.icon-warehouse .path4:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(236, 193, 156);
}
.icon-warehouse .path5:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(255, 130, 110);
}
.icon-warehouse .path6:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(252, 110, 81);
}
.icon-warehouse .path7:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(105, 214, 244);
}
.icon-warehouse .path8:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}
.icon-warehouse .path9:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}
.icon-warehouse .path10:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}
.icon-warehouse .path11:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}
.icon-warehouse .path12:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(150, 159, 170);
}
.icon-warehouse .path13:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(150, 159, 170);
}
.icon-warehouse .path14:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(211, 160, 108);
}
.icon-warehouse .path15:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-warehouse .path16:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-warehouse .path17:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-almacen .path1:before {
  content: "\eab5";
  color: rgb(141, 150, 158);
}
.icon-almacen .path2:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(172, 181, 188);
}
.icon-almacen .path3:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(214, 9, 73);
}
.icon-almacen .path4:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(183, 2, 67);
}
.icon-almacen .path5:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}
.icon-almacen .path6:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}
.icon-almacen .path7:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}
.icon-almacen .path8:before {
  content: "\eabc";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}
.icon-almacen .path9:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}
.icon-almacen .path10:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}
.icon-almacen .path11:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}
.icon-almacen .path12:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}
.icon-almacen .path13:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}
.icon-zip .path1:before {
  content: "\eac2";
  color: rgb(255, 145, 0);
}
.icon-zip .path2:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-zip .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-zip .path4:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-zip .path5:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-add .path1:before {
  content: "\e901";
  color: rgb(76, 175, 80);
}
.icon-add .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-add .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-addressbook .path1:before {
  content: "\e904";
  color: rgb(103, 58, 183);
}
.icon-addressbook .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(49, 27, 146);
}
.icon-addressbook .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-approval .path1:before {
  content: "\e907";
  color: rgb(139, 195, 74);
}
.icon-approval .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(204, 255, 144);
}
.icon-business:before {
  content: "\e90c";
}
.icon-cancel .path1:before {
  content: "\e90d";
  color: rgb(244, 67, 54);
}
.icon-cancel .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cancel .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-category .path1:before {
  content: "\e956";
  color: rgb(144, 202, 249);
}
.icon-category .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-chart-line:before {
  content: "\e959";
}
.icon-check .path1:before {
  content: "\e957";
  color: rgb(149, 117, 205);
}
.icon-check .path2:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-check .path3:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path4:before {
  content: "\eb42";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path5:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path6:before {
  content: "\eb44";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path7:before {
  content: "\eb45";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path8:before {
  content: "\eb46";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path9:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-check .path10:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-company .path1:before {
  content: "\e95d";
  color: rgb(30, 136, 229);
}
.icon-company .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-company .path3:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}
.icon-company .path4:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}
.icon-company .path5:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}
.icon-company .path6:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}
.icon-company .path7:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-company .path8:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}
.icon-company .path9:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path10:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path11:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path12:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path13:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path14:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path15:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path16:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path17:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path18:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path19:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-company .path20:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}
.icon-config .path1:before {
  content: "\e977";
  color: rgb(230, 81, 0);
}
.icon-config .path2:before {
  content: "\eb49";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}
.icon-excel .path1:before {
  content: "\e990";
  color: rgb(76, 175, 80);
}
.icon-excel .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path7:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path8:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path9:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-excel .path10:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(46, 125, 50);
}
.icon-excel .path11:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gas .path1:before {
  content: "\e9a2";
  color: rgb(66, 165, 245);
}
.icon-gas .path2:before {
  content: "\eb4a";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-gas .path3:before {
  content: "\eb4b";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-gas .path4:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.icon-gas .path5:before {
  content: "\eb4d";
  margin-left: -1em;
  color: rgb(178, 235, 242);
}
.icon-gas .path6:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}
.icon-gas .path7:before {
  content: "\eb4f";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}
.icon-gas .path8:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}
.icon-help .path1:before {
  content: "\e9b5";
  color: rgb(33, 150, 243);
}
.icon-help .path2:before {
  content: "\eb51";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-help .path3:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-info .path1:before {
  content: "\e9b7";
  color: rgb(33, 150, 243);
}
.icon-info .path2:before {
  content: "\eb53";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info .path3:before {
  content: "\eb54";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-logout .path1:before {
  content: "\e9c8";
  color: rgb(244, 67, 54);
}
.icon-logout .path2:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 205, 210);
}
.icon-logout .path3:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 205, 210);
}
.icon-order .path1:before {
  content: "\e9d2";
  color: rgb(197, 202, 233);
}
.icon-order .path2:before {
  content: "\eb55";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path3:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path4:before {
  content: "\eb57";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path5:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path6:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path7:before {
  content: "\eb5a";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}
.icon-order .path8:before {
  content: "\eb5b";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}
.icon-orderhistory .path1:before {
  content: "\e9da";
  color: rgb(144, 202, 249);
}
.icon-orderhistory .path2:before {
  content: "\eb5c";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path3:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path4:before {
  content: "\eb5e";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path5:before {
  content: "\eb5f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path6:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(115, 188, 247);
}
.icon-orderhistory .path7:before {
  content: "\eb61";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path8:before {
  content: "\eb62";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-orderhistory .path9:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}
.icon-orderhistory .path10:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}
.icon-orderhistory .path11:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(255, 224, 178);
}
.icon-orderhistory .path12:before {
  content: "\eb66";
  margin-left: -1em;
  color: rgb(141, 110, 99);
}
.icon-orderhistory .path13:before {
  content: "\eb67";
  margin-left: -1em;
  color: rgb(109, 76, 65);
}
.icon-orderhistory .path14:before {
  content: "\eb68";
  margin-left: -1em;
  color: rgb(251, 140, 0);
}
.icon-pdf .path1:before {
  content: "\e9ec";
  color: rgb(231, 76, 60);
}
.icon-pdf .path2:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(251, 233, 231);
}
.icon-pdf .path3:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-previous:before {
  content: "\e9f6";
  color: #2196f3;
}
.icon-price .path1:before {
  content: "\e9f7";
  color: rgb(63, 81, 181);
}
.icon-price .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(255, 245, 157);
}
.icon-pricing .path1:before {
  content: "\e9f9";
  color: rgb(76, 175, 80);
}
.icon-pricing .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-printing .path1:before {
  content: "\e9fb";
  color: rgb(97, 97, 97);
}
.icon-printing .path2:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-printing .path3:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-printing .path4:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(0, 230, 118);
}
.icon-printing .path5:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(36, 36, 36);
}
.icon-printing .path6:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-printing .path7:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-printing .path8:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}
.icon-printing .path9:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-printing .path10:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-product .path1:before {
  content: "\ea05";
  color: rgb(255, 152, 0);
}
.icon-product .path2:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}
.icon-product .path3:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}
.icon-product .path4:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}
.icon-product .path5:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}
.icon-product .path6:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}
.icon-product .path7:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 170, 46);
}
.icon-receipt .path1:before {
  content: "\ea0e";
  color: rgb(26, 188, 156);
}
.icon-receipt .path2:before {
  content: "\eb69";
  margin-left: -1em;
  color: rgb(232, 234, 246);
}
.icon-receipt .path3:before {
  content: "\eb6a";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path4:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path5:before {
  content: "\eb6c";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path6:before {
  content: "\eb6d";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path7:before {
  content: "\eb6e";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path8:before {
  content: "\eb6f";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path9:before {
  content: "\eb70";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}
.icon-receipt .path10:before {
  content: "\eb71";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}
.icon-receipt .path11:before {
  content: "\eb72";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}
.icon-saveall .path1:before {
  content: "\ea1e";
  color: rgb(2, 119, 189);
}
.icon-saveall .path2:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(144, 164, 174);
}
.icon-saveall .path3:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.icon-saveall .path4:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(52, 152, 219);
}
.icon-saveall .path5:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-saveall .path6:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}
.icon-saveall .path7:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}
.icon-saveall .path8:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}
.icon-saveall .path9:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(41, 128, 185);
}
.icon-saveall .path10:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.icon-saveall .path11:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}
.icon-saveall .path12:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(1, 87, 155);
}
.icon-saveall .path13:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(120, 144, 156);
}
.icon-saveall .path14:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(38, 50, 56);
}
.icon-send2print .path1:before {
  content: "\ea2d";
  color: rgb(97, 97, 97);
}
.icon-send2print .path2:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-send2print .path3:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}
.icon-send2print .path4:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(0, 230, 118);
}
.icon-send2print .path5:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(36, 36, 36);
}
.icon-send2print .path6:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-send2print .path7:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}
.icon-send2print .path8:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}
.icon-send2print .path9:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-send2print .path10:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-send2print .path11:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-send2print .path12:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-service .path1:before {
  content: "\ea48";
  color: rgb(76, 175, 80);
}
.icon-service .path2:before {
  content: "\eb73";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-service .path3:before {
  content: "\eb74";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-service .path4:before {
  content: "\eb75";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-service .path5:before {
  content: "\eb76";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-service .path6:before {
  content: "\eb77";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}
.icon-service .path7:before {
  content: "\eb78";
  margin-left: -1em;
  color: rgb(129, 199, 132);
}
.icon-sidemenu .path1:before {
  content: "\ea47";
  color: rgb(187, 222, 251);
}
.icon-sidemenu .path2:before {
  content: "\eb79";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}
.icon-sidemenu .path3:before {
  content: "\eb7a";
  margin-left: -1em;
  color: rgb(255, 235, 59);
}
.icon-sidemenu .path4:before {
  content: "\eb7b";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}
.icon-sidemenu .path5:before {
  content: "\eb7c";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-store .path1:before {
  content: "\ea49";
  color: rgb(207, 216, 220);
}
.icon-store .path2:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.icon-store .path3:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-store .path4:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-store .path5:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}
.icon-store .path6:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}
.icon-store .path7:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}
.icon-store .path8:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}
.icon-store .path9:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-store .path10:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-store .path11:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-store .path12:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}
.icon-store .path13:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}
.icon-store .path14:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}
.icon-store .path15:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}
.icon-store .path16:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}
.icon-store .path17:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-store .path18:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-store .path19:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-store .path20:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-store .path21:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-store .path22:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}
.icon-store .path23:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}
.icon-store .path24:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}
.icon-tag:before {
  content: "\ea78";
  color: #f35517;
}
.icon-widget:before {
  content: "\ea9a";
}
.icon-food:before {
  content: "\e900";
}
.icon-printer-bk:before {
  content: "\e909";
}
.icon-document-edit:before {
  content: "\e90a";
}
.icon-document-certificate:before {
  content: "\e91e";
}
.icon-certificate:before {
  content: "\e91f";
}
.icon-certificate-bg:before {
  content: "\e924";
}
.icon-box:before {
  content: "\e925";
}
.icon-award:before {
  content: "\e910";
}
.icon-award-bk:before {
  content: "\e911";
}
.icon-pie-chart:before {
  content: "\e912";
}
.icon-medal:before {
  content: "\e913";
}
.icon-medal-bg:before {
  content: "\e914";
}
.icon-locked:before {
  content: "\e915";
}
.icon-unlocked:before {
  content: "\e916";
}
.icon-box-open:before {
  content: "\e917";
}
.icon-box2:before {
  content: "\e918";
}
.icon-coins:before {
  content: "\e919";
}
.icon-music:before {
  content: "\e91a";
}
.icon-male:before {
  content: "\e91b";
}
.icon-female:before {
  content: "\e91c";
}
.icon-aids:before {
  content: "\e91d";
}
.icon-bus:before {
  content: "\e920";
}
.icon-bike1:before {
  content: "\e921";
}
.icon-plane:before {
  content: "\e922";
}
.icon-globe:before {
  content: "\e923";
}
.icon-assignment_ind:before {
  content: "\e95c";
}
.icon-translate:before {
  content: "\e95b";
}
.icon-checkroom:before {
  content: "\e95a";
}
.icon-stats:before {
  content: "\e97b";
}
.icon-floppy:before {
  content: "\e934";
}
.icon-pause:before {
  content: "\e926";
}
.icon-stop:before {
  content: "\e927";
}
.icon-eject:before {
  content: "\e928";
}
.icon-first:before {
  content: "\e929";
}
.icon-last:before {
  content: "\e92a";
}
.icon-play-alt:before {
  content: "\e92b";
}
.icon-home:before {
  content: "\e92c";
}
.icon-edificio:before {
  content: "\e92d";
}
.icon-newspaper:before {
  content: "\e92e";
}
.icon-edit:before {
  content: "\e92f";
}
.icon-paint-format:before {
  content: "\e930";
}
.icon-image:before {
  content: "\e931";
}
.icon-images:before {
  content: "\e932";
}
.icon-camera:before {
  content: "\e933";
}
.icon-film:before {
  content: "\e935";
}
.icon-wifi:before {
  content: "\e936";
}
.icon-mic:before {
  content: "\e937";
}
.icon-book:before {
  content: "\e938";
}
.icon-books:before {
  content: "\e939";
}
.icon-library:before {
  content: "\e93a";
}
.icon-profile:before {
  content: "\e93b";
}
.icon-copy:before {
  content: "\e93c";
}
.icon-paste:before {
  content: "\e93d";
}
.icon-folder:before {
  content: "\e93e";
}
.icon-folder-open:before {
  content: "\e93f";
}
.icon-folder-plus:before {
  content: "\e940";
}
.icon-folder-minus:before {
  content: "\e941";
}
.icon-folder-download:before {
  content: "\e942";
}
.icon-folder-upload:before {
  content: "\e943";
}
.icon-price-tag:before {
  content: "\e944";
}
.icon-price-tags:before {
  content: "\e945";
}
.icon-barcode:before {
  content: "\e946";
}
.icon-qrcode:before {
  content: "\e947";
}
.icon-cart:before {
  content: "\e948";
}
.icon-calculator:before {
  content: "\e949";
}
.icon-phone:before {
  content: "\e94a";
}
.icon-address-book:before {
  content: "\e94b";
}
.icon-pin:before {
  content: "\e94c";
}
.icon-pinpoint:before {
  content: "\e94d";
}
.icon-map:before {
  content: "\e94e";
}
.icon-bk-history:before {
  content: "\e94f";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-stopwatch:before {
  content: "\e952";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-printer:before {
  content: "\e954";
}
.icon-keyboard:before {
  content: "\e955";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-undo:before {
  content: "\e967";
}
.icon-redo:before {
  content: "\e968";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-user-check:before {
  content: "\e975";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-binoculars:before {
  content: "\e985";
}
.icon-search:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-shrink:before {
  content: "\e98a";
}
.icon-key:before {
  content: "\e98d";
}
.icon-key2:before {
  content: "\e98e";
}
.icon-equalizer:before {
  content: "\e993";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-hammer:before {
  content: "\e996";
}
.icon-pie-chart-b:before {
  content: "\e99a";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-stats-bars:before {
  content: "\e99d";
}
.icon-glass:before {
  content: "\e9a4";
}
.icon-spoon-knife:before {
  content: "\e9a3";
}
.icon-meter:before {
  content: "\e9a6";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-target:before {
  content: "\e9b3";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-cloud-upload:before {
  content: "\e9c3";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-bookmark:before {
  content: "\e9d4";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-warning:before {
  content: "\ea19";
}
.icon-notification:before {
  content: "\ea18";
}
.icon-question:before {
  content: "\ea17";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info1:before {
  content: "\ea16";
}
.icon-cancel-circle:before {
  content: "\ea15";
}
.icon-blocked:before {
  content: "\ea12";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-infinite:before {
  content: "\ea2f";
}
.icon-shuffle:before {
  content: "\ea30";
}
.icon-arrow-up-left:before {
  content: "\ea31";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down-right:before {
  content: "\ea35";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-down-left:before {
  content: "\ea37";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-check:before,
.icon-checked:before {
  content: "\ea52";
}
.icon-uncheck:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked-bg:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-crop:before {
  content: "\ea57";
}
.icon-ungroup:before {
  content: "\ea59";
}
.icon-scissors:before {
  content: "\ea5a";
}
.icon-filter:before {
  content: "\ea5b";
}
.icon-text-height:before {
  content: "\ea5f";
}
.icon-text-width:before {
  content: "\ea60";
}
.icon-font-size:before {
  content: "\ea61";
}
.icon-bold:before {
  content: "\ea62";
}
.icon-underline:before {
  content: "\ea63";
}
.icon-italic:before {
  content: "\ea64";
}
.icon-strikethrough:before {
  content: "\ea65";
}
.icon-sigma:before {
  content: "\ea67";
}
.icon-superscript:before {
  content: "\ea69";
}
.icon-subscript:before {
  content: "\ea6a";
}
.icon-text-color:before {
  content: "\ea6d";
}
.icon-table:before {
  content: "\ea71";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-embed:before {
  content: "\ea7f";
}
.icon-embed2:before {
  content: "\ea80";
}
.icon-terminal:before {
  content: "\ea81";
}
.icon-share:before {
  content: "\ea82";
}
.icon-mail:before {
  content: "\ea83";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-safari:before {
  content: "\eadd";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-libreoffice:before {
  content: "\eae3";
}
.icon-spinner:before {
  content: "\eb1a";
}
.icon-spinner11:before {
  content: "\eb1b";
}
.icon-th-large:before {
  content: "\f009";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-bell-o:before {
  content: "\f0a2";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users1:before {
  content: "\f0c0";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-medkit:before {
  content: "\f0fa";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-wheelchair:before {
  content: "\f193";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-paw:before {
  content: "\f1b0";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-file-image-o:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-audio-o:before {
  content: "\f1c7";
}
.icon-file-sound-o:before {
  content: "\f1c7";
}
.icon-file-movie-o:before {
  content: "\f1c8";
}
.icon-file-video-o:before {
  content: "\f1c8";
}
.icon-file-code-o:before {
  content: "\f1c9";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-calendar-plus-o:before {
  content: "\f271";
}
.icon-calendar-minus-o:before {
  content: "\f272";
}
.icon-industry:before {
  content: "\f275";
}
.icon-usb:before {
  content: "\f287";
}
.icon-bluetooth:before {
  content: "\f293";
}
