@charset "UTF-8";

/*
$modal-xl:                          //1140px !default;
$modal-lg:                          //800px !default;
$modal-md:                          //500px !default;
$modal-sm:                          //300px !default;
*/

/*
// Typography
$font-family-sans-serif: 'Tahoma',
sans-serif;
$s-base: 1rem;
$line-height-base: 1;
*/

/*
*/

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3490dc;
  --secondary: #6c757d;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 456px;
  --breakpoint-md: 789px;
  --breakpoint-lg: 999px;
  --breakpoint-xl: 1234px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.55rem;
  font-weight: 400;
  line-height: 1.2;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.375rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3490dc;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #1d68a7;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.375rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 1.375rem;
}

h2,
.h2 {
  font-size: 1.1rem;
}

h3,
.h3 {
  font-size: 0.9625rem;
}

h4,
.h4 {
  font-size: 0.825rem;
}

h5,
.h5 {
  font-size: 0.6875rem;
}

h6,
.h6 {
  font-size: 0.55rem;
}

.lead {
  font-size: 0.6875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0.75rem;
  font-size: 0.6875rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.375rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 456px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 789px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 999px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1234px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 456px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 789px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 999px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1234px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 0.75rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.5em;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6e0f5;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95c5ed;
}

.table-hover .table-primary:hover {
  background-color: #b0d4f1;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0d4f1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7eed8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-success:hover {
  background-color: #b3e8ca;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e9f9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3d7f5;
}

.table-hover .table-info:hover {
  background-color: #c0ddf6;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffacc;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff6a1;
}

.table-hover .table-warning:hover {
  background-color: #fff8b3;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c6c5;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09593;
}

.table-hover .table-danger:hover {
  background-color: #f4b0af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 455.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 788.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 998.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1233.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0.2rem 0.5rem;
  font-size: 0.55rem;
  font-weight: 400;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.2rem + 1px);
  padding-bottom: calc(0.2rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2;
}

.col-form-label-lg {
  padding-top: calc(0.24rem + 1px);
  padding-bottom: calc(0.24rem + 1px);
  font-size: 0.6875rem;
  line-height: 1.2;
}

.col-form-label-sm {
  padding-top: calc(0.18rem + 1px);
  padding-bottom: calc(0.18rem + 1px);
  font-size: 0.48125rem;
  line-height: 1.2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.2rem 0;
  margin-bottom: 0;
  font-size: 0.55rem;
  line-height: 1.2;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.2em + 0.36rem + 2px);
  padding: 0.18rem 0.45rem;
  font-size: 0.48125rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.2em + 0.48rem + 2px);
  padding: 0.24rem 0.6rem;
  font-size: 0.6875rem;
  line-height: 1.2;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #38c172;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.15rem 0.25rem;
  margin-top: 0.1rem;
  font-size: 0.48125rem;
  line-height: 1.2;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.2em + 0.4rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.1rem) center;
  background-size: calc(0.6em + 0.2rem) calc(0.6em + 0.2rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 2rem !important;
  background-position: right 1rem center;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.2em + 0.4rem);
  background-position: top calc(0.3em + 0.1rem) right calc(0.3em + 0.1rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 1.8rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.5rem/calc(0.6em + 0.2rem) calc(0.6em + 0.2rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e3342f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.15rem 0.25rem;
  margin-top: 0.1rem;
  font-size: 0.48125rem;
  line-height: 1.2;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.2em + 0.4rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.1rem) center;
  background-size: calc(0.6em + 0.2rem) calc(0.6em + 0.2rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 2rem !important;
  background-position: right 1rem center;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.2em + 0.4rem);
  background-position: top calc(0.3em + 0.1rem) right calc(0.3em + 0.1rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 1.8rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") center right 1.5rem/calc(0.6em + 0.2rem) calc(0.6em + 0.2rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 456px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.2rem 0.5rem;
  font-size: 0.55rem;
  line-height: 1.2;
  border-radius: 0.25rem;
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-primary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe817;
  border-color: #ffe70a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #3490dc;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3490dc;
  text-decoration: none;
}

.btn-link:hover {
  color: #1d68a7;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.24rem 0.6rem;
  font-size: 0.6875rem;
  line-height: 1.2;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.18rem 0.45rem;
  font-size: 0.48125rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
}

.collapsing.width {
  width: 0;
  height: auto;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.55rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 456px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 789px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 999px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1234px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.375rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3490dc;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.48125rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.3375rem;
  padding-left: 0.3375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.45rem;
  padding-left: 0.45rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0;
  font-size: 0.55rem;
  font-weight: 400;
  line-height: 1.2;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.2em + 0.48rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.24rem 0.6rem;
  font-size: 0.6875rem;
  line-height: 1.2;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.2em + 0.36rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.18rem 0.45rem;
  font-size: 0.48125rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.5rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 0.66rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 0.83rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3490dc;
  background-color: #3490dc;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a1cbef;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cce3f6;
  border-color: #cce3f6;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: -0.17rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: -0.17rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3490dc;
  background-color: #3490dc;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(-0.17rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0.2rem 1.5rem 0.2rem 0.5rem;
  font-size: 0.55rem;
  font-weight: 400;
  line-height: 1.2;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.5rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.2em + 0.36rem + 2px);
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  padding-left: 0.45rem;
  font-size: 0.48125rem;
}

.custom-select-lg {
  height: calc(1.2em + 0.48rem + 2px);
  padding-top: 0.24rem;
  padding-bottom: 0.24rem;
  padding-left: 0.6rem;
  font-size: 0.6875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.2em + 0.4rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.2em + 0.4rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #a1cbef;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0.2rem 0.5rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.2em + 0.4rem);
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #cce3f6;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-moz-range-thumb:active {
  background-color: #cce3f6;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-ms-thumb:active {
  background-color: #cce3f6;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.15rem 0.5rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3490dc;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem 0.75rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.0675rem;
  padding-bottom: 0.0675rem;
  margin-right: 0.75rem;
  font-size: 0.6875rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.15rem 0.5rem;
  font-size: 0.6875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 455.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 456px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 788.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 789px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 998.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 999px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1233.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1234px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.2rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 456px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 456px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.5rem;
}

@media (min-width: 456px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.2rem 0.5rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 1rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.2rem 0.5rem;
  margin-left: -1px;
  line-height: 1.2;
  color: #3490dc;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #1d68a7;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.24rem 0.6rem;
  font-size: 0.6875rem;
  line-height: 1.2;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.18rem 0.45rem;
  font-size: 0.48125rem;
  line-height: 1.2;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3490dc;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #2176bd;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #38c172;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #6cb2eb;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #3f9ae5;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffed4a;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ffe817;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e3342f;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 456px) {
  .jumbotron {
    padding: 2rem 1rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.825rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem 1rem;
  color: inherit;
}

.alert-primary {
  color: #1b4b72;
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}

.alert-primary hr {
  border-top-color: #b0d4f1;
}

.alert-primary .alert-link {
  color: #113049;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}

.alert-success hr {
  border-top-color: #b3e8ca;
}

.alert-success .alert-link {
  color: #123c24;
}

.alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}

.alert-info hr {
  border-top-color: #c0ddf6;
}

.alert-info .alert-link {
  color: #284257;
}

.alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}

.alert-warning hr {
  border-top-color: #fff8b3;
}

.alert-warning .alert-link {
  color: #5d561b;
}

.alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}

.alert-danger hr {
  border-top-color: #f4b0af;
}

.alert-danger .alert-link {
  color: #4c110f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.4125rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3490dc;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 456px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 789px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 999px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1234px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1b4b72;
  background-color: #c6e0f5;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #1b4b72;
  background-color: #b0d4f1;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b4b72;
  border-color: #1b4b72;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}

.list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}

.list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 0.825rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.5rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.15rem 0.5rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.5rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.2;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 456px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.2rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 2.4rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 2.4rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 2.4rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 2.4rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 999px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1234px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.48125rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top],
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right],
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom],
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left],
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.15rem 0.25rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.48125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.b-popover-dark.bs-popover-auto[x-placement^=top],
.b-popover-light.bs-popover-auto[x-placement^=top],
.b-popover-danger.bs-popover-auto[x-placement^=top],
.b-popover-warning.bs-popover-auto[x-placement^=top],
.b-popover-info.bs-popover-auto[x-placement^=top],
.b-popover-success.bs-popover-auto[x-placement^=top],
.b-popover-secondary.bs-popover-auto[x-placement^=top],
.b-popover-primary.bs-popover-auto[x-placement^=top],
.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.b-popover-dark.bs-popover-auto[x-placement^=right],
.b-popover-light.bs-popover-auto[x-placement^=right],
.b-popover-danger.bs-popover-auto[x-placement^=right],
.b-popover-warning.bs-popover-auto[x-placement^=right],
.b-popover-info.bs-popover-auto[x-placement^=right],
.b-popover-success.bs-popover-auto[x-placement^=right],
.b-popover-secondary.bs-popover-auto[x-placement^=right],
.b-popover-primary.bs-popover-auto[x-placement^=right],
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.b-popover-dark.bs-popover-auto[x-placement^=bottom],
.b-popover-light.bs-popover-auto[x-placement^=bottom],
.b-popover-danger.bs-popover-auto[x-placement^=bottom],
.b-popover-warning.bs-popover-auto[x-placement^=bottom],
.b-popover-info.bs-popover-auto[x-placement^=bottom],
.b-popover-success.bs-popover-auto[x-placement^=bottom],
.b-popover-secondary.bs-popover-auto[x-placement^=bottom],
.b-popover-primary.bs-popover-auto[x-placement^=bottom],
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.b-popover-dark.bs-popover-auto[x-placement^=left],
.b-popover-light.bs-popover-auto[x-placement^=left],
.b-popover-danger.bs-popover-auto[x-placement^=left],
.b-popover-warning.bs-popover-auto[x-placement^=left],
.b-popover-info.bs-popover-auto[x-placement^=left],
.b-popover-success.bs-popover-auto[x-placement^=left],
.b-popover-secondary.bs-popover-auto[x-placement^=left],
.b-popover-primary.bs-popover-auto[x-placement^=left],
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.2rem 0.5rem;
  margin-bottom: 0;
  font-size: 0.55rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.2rem 0.5rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3490dc !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2176bd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #38c172 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d995b !important;
}

.bg-info {
  background-color: #6cb2eb !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f9ae5 !important;
}

.bg-warning {
  background-color: #ffed4a !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe817 !important;
}

.bg-danger {
  background-color: #e3342f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c51f1a !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3490dc !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #38c172 !important;
}

.border-info {
  border-color: #6cb2eb !important;
}

.border-warning {
  border-color: #ffed4a !important;
}

.border-danger {
  border-color: #e3342f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 456px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 789px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 999px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1234px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 456px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 789px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 999px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1234px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 456px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 789px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 999px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1234px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.1875rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.1875rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.1875rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.1875rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.1875rem !important;
}

.m-2 {
  margin: 0.375rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.375rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.375rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.375rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.375rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1.125rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.125rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.125rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.125rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.125rem !important;
}

.m-5 {
  margin: 2.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.25rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.25rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.25rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.1875rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.1875rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.1875rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.1875rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.1875rem !important;
}

.p-2 {
  padding: 0.375rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.375rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.375rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.375rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.375rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1.125rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.125rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.125rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.125rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.125rem !important;
}

.p-5 {
  padding: 2.25rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.25rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.25rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.25rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.25rem !important;
}

.m-n1 {
  margin: -0.1875rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.1875rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.1875rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.1875rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.1875rem !important;
}

.m-n2 {
  margin: -0.375rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.375rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.375rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.375rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.375rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1.125rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.125rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.125rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.125rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.125rem !important;
}

.m-n5 {
  margin: -2.25rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.25rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.25rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.25rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 456px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.1875rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.1875rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.1875rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.1875rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.1875rem !important;
  }

  .m-sm-2 {
    margin: 0.375rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.375rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.375rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.375rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1.125rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.125rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.125rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.125rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.125rem !important;
  }

  .m-sm-5 {
    margin: 2.25rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.25rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.25rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.25rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.25rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.1875rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.1875rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.1875rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.1875rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.1875rem !important;
  }

  .p-sm-2 {
    padding: 0.375rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.375rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.375rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.375rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1.125rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.125rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.125rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.125rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.125rem !important;
  }

  .p-sm-5 {
    padding: 2.25rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.25rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.25rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.25rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.25rem !important;
  }

  .m-sm-n1 {
    margin: -0.1875rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.1875rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.1875rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.1875rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.1875rem !important;
  }

  .m-sm-n2 {
    margin: -0.375rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.375rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1.125rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.125rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.125rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.125rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.125rem !important;
  }

  .m-sm-n5 {
    margin: -2.25rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.25rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.25rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.25rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.25rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 789px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.1875rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.1875rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.1875rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.1875rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.1875rem !important;
  }

  .m-md-2 {
    margin: 0.375rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.375rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.375rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.375rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1.125rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.125rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.125rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.125rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.125rem !important;
  }

  .m-md-5 {
    margin: 2.25rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 2.25rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.25rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.25rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.25rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.1875rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.1875rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.1875rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.1875rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.1875rem !important;
  }

  .p-md-2 {
    padding: 0.375rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.375rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.375rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.375rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1.125rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.125rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.125rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.125rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.125rem !important;
  }

  .p-md-5 {
    padding: 2.25rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 2.25rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 2.25rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.25rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 2.25rem !important;
  }

  .m-md-n1 {
    margin: -0.1875rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.1875rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.1875rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.1875rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.1875rem !important;
  }

  .m-md-n2 {
    margin: -0.375rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.375rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1.125rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.125rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.125rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.125rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.125rem !important;
  }

  .m-md-n5 {
    margin: -2.25rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.25rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.25rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.25rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.25rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 999px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.1875rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.1875rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.1875rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.1875rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.1875rem !important;
  }

  .m-lg-2 {
    margin: 0.375rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.375rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.375rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.375rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1.125rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.125rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.125rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.125rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.125rem !important;
  }

  .m-lg-5 {
    margin: 2.25rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.25rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.25rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.25rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.25rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.1875rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.1875rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.1875rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.1875rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.1875rem !important;
  }

  .p-lg-2 {
    padding: 0.375rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.375rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.375rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.375rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1.125rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.125rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.125rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.125rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.125rem !important;
  }

  .p-lg-5 {
    padding: 2.25rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.25rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.25rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.25rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.25rem !important;
  }

  .m-lg-n1 {
    margin: -0.1875rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.1875rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.1875rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.1875rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.1875rem !important;
  }

  .m-lg-n2 {
    margin: -0.375rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.375rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1.125rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.125rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.125rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.125rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.125rem !important;
  }

  .m-lg-n5 {
    margin: -2.25rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.25rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.25rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.25rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.25rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1234px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.1875rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.1875rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.1875rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.1875rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.1875rem !important;
  }

  .m-xl-2 {
    margin: 0.375rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.375rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.375rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.375rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.375rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1.125rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.125rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.125rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.125rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.125rem !important;
  }

  .m-xl-5 {
    margin: 2.25rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.25rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.25rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.25rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.25rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.1875rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.1875rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.1875rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.1875rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.1875rem !important;
  }

  .p-xl-2 {
    padding: 0.375rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.375rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.375rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.375rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.375rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1.125rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.125rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.125rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.125rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.125rem !important;
  }

  .p-xl-5 {
    padding: 2.25rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.25rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.25rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.25rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.25rem !important;
  }

  .m-xl-n1 {
    margin: -0.1875rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.1875rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.1875rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.1875rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.1875rem !important;
  }

  .m-xl-n2 {
    margin: -0.375rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.375rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.375rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.375rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.375rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1.125rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.125rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.125rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.125rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.125rem !important;
  }

  .m-xl-n5 {
    margin: -2.25rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.25rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.25rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.25rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.25rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 456px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 789px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 999px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1234px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3490dc !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #1d68a7 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #38c172 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #27864f !important;
}

.text-info {
  color: #6cb2eb !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #298fe2 !important;
}

.text-warning {
  color: #ffed4a !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fde300 !important;
}

.text-danger {
  color: #e3342f !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ae1c17 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a4;
}

  body {
    min-width: 999px !important;
  }

  .container {
    min-width: 999px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 455.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}

@media (max-width: 788.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}

@media (max-width: 998.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}

@media (max-width: 1233.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}

.bv-d-xl-down-none {
  display: none !important;
}

.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.form-control.focus.is-valid {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.form-control.focus.is-invalid {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.b-avatar:focus {
  outline: 0;
}

.b-avatar.btn,
.b-avatar[href] {
  padding: 0;
  border: 0;
}

.b-avatar.btn .b-avatar-img img,
.b-avatar[href] .b-avatar-img img {
  transition: transform 0.15s ease-in-out;
}

.b-avatar.btn:not(:disabled):not(.disabled),
.b-avatar[href]:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img,
.b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
  transform: scale(1.15);
}

.b-avatar.disabled,
.b-avatar:disabled,
.b-avatar[disabled] {
  opacity: 0.65;
  pointer-events: none;
}

.b-avatar .b-avatar-custom,
.b-avatar .b-avatar-text,
.b-avatar .b-avatar-img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: radial-gradient(white, black);
}

.b-avatar .b-avatar-text {
  text-transform: uppercase;
  white-space: nowrap;
}

.b-avatar[href] {
  text-decoration: none;
}

.b-avatar > .b-icon {
  width: 60%;
  height: auto;
  max-width: 100%;
}

.b-avatar .b-avatar-img img {
  width: 100%;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  object-fit: cover;
}

.b-avatar .b-avatar-badge {
  position: absolute;
  min-height: 1.5em;
  min-width: 1.5em;
  padding: 0.25em;
  line-height: 1;
  border-radius: 10em;
  font-size: 70%;
  font-weight: 700;
  z-index: 1;
}

.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.b-avatar-sm .b-avatar-text {
  font-size: calc(0.6rem);
}

.b-avatar-sm .b-avatar-badge {
  font-size: calc(0.42rem);
}

.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}

.b-avatar-lg .b-avatar-text {
  font-size: calc(1.4rem);
}

.b-avatar-lg .b-avatar-badge {
  font-size: calc(0.98rem);
}

.b-avatar-group .b-avatar-group-inner {
  display: flex;
  flex-wrap: wrap;
}

.b-avatar-group .b-avatar {
  border: 1px solid #dee2e6;
}

.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),
.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}

.b-calendar {
  display: inline-flex;
}

.b-calendar .b-calendar-inner {
  min-width: 250px;
}

.b-calendar .b-calendar-header,
.b-calendar .b-calendar-nav {
  margin-bottom: 0.25rem;
}

.b-calendar .b-calendar-nav .btn {
  padding: 0.25rem;
}

.b-calendar output {
  padding: 0.25rem;
  font-size: 80%;
}

.b-calendar output.readonly {
  background-color: #e9ecef;
  opacity: 1;
}

.b-calendar .b-calendar-footer {
  margin-top: 0.5rem;
}

.b-calendar .b-calendar-grid {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}

.b-calendar .b-calendar-grid-caption {
  padding: 0.25rem;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
  margin: 3px auto;
  padding: 9px 0;
}

.b-calendar .btn:disabled,
.b-calendar .btn.disabled,
.b-calendar .btn[aria-disabled=true] {
  cursor: default;
  pointer-events: none;
}

.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-img-right {
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}

.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}

.dropdown .dropdown-menu:focus {
  outline: none;
}

.b-dropdown-form {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400;
}

.b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}

.b-dropdown-form.disabled,
.b-dropdown-form:disabled {
  outline: 0 !important;
  color: #adb5bd;
  pointer-events: none;
}

.b-dropdown-text {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 0.6875rem;
  line-height: 1.2;
  padding-left: 1.875rem;
}

.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: -0.2125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.3rem;
}

.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: -0.2125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background-size: 50% 50%;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.48125rem;
  line-height: 1.2;
  padding-left: 1.3125rem;
}

.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: -0.14875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.2rem;
}

.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: -0.14875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125rem;
}

.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 0.6875rem;
  line-height: 1.2;
}

.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: -0.2125rem;
  height: 1.25rem;
  left: -2.8125rem;
  width: 2.1875rem;
  border-radius: 0.625rem;
}

.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc(
        -0.2125rem + 2px
      );
  left: calc(
        -2.8125rem + 2px
      );
  width: calc(
  1.25rem - 4px
);
  height: calc(
  1.25rem - 4px
);
  border-radius: 0.625rem;
  background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.9375rem);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875rem;
}

.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.48125rem;
  line-height: 1.2;
}

.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: -0.14875rem;
  left: -1.96875rem;
  width: 1.53125rem;
  height: 0.875rem;
  border-radius: 0.4375rem;
}

.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc(
        -0.14875rem + 2px
      );
  left: calc(
        -1.96875rem + 2px
      );
  width: calc(
  0.875rem - 4px
);
  height: calc(
  0.875rem - 4px
);
  border-radius: 0.4375rem;
  background-size: 50% 50%;
}

.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.65625rem);
}

.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}

.input-group .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir=rtl] .b-form-btn-label-control.form-control,
.b-form-btn-label-control.form-control[dir=rtl] {
  flex-direction: row-reverse;
}

[dir=rtl] .b-form-btn-label-control.form-control > label,
.b-form-btn-label-control.form-control[dir=rtl] > label {
  text-align: right;
}

.b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  box-shadow: none !important;
  border: 0;
}

.b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}

.b-form-btn-label-control.form-control.is-valid > .btn {
  color: #38c172;
}

.b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #e3342f;
}

.b-form-btn-label-control.form-control > .dropdown-menu {
  padding: 0.5rem;
}

.b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(calc(1.2em + 0.4rem + 2px) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(calc(1.2em + 0.36rem + 2px) - 2px);
}

.b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(calc(1.2em + 0.48rem + 2px) - 2px);
}

.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.2em + 0.36rem + 2px) - 2px);
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
}

.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.2em + 0.48rem + 2px) - 2px);
  padding-top: 0.24rem;
  padding-bottom: 0.24rem;
}

.b-form-btn-label-control.form-control[aria-disabled=true],
.b-form-btn-label-control.form-control[aria-readonly=true] {
  background-color: #e9ecef;
  opacity: 1;
}

.b-form-btn-label-control.form-control[aria-disabled=true] {
  pointer-events: none;
}

.b-form-btn-label-control.form-control[aria-disabled=true] > label {
  cursor: default;
}

.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 0.6875rem;
  height: calc(1.2em + 0.48rem + 2px);
}

.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.24rem 0.6rem;
  line-height: 1.2;
}

.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.3rem;
}

.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  font-size: inherit;
  height: calc(
  1.2em + 0.48rem
);
  border-radius: 0 0.3rem 0.3rem 0;
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.48125rem;
  height: calc(1.2em + 0.36rem + 2px);
}

.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.18rem 0.45rem;
  line-height: 1.2;
}

.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2rem;
}

.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: calc(
  1.2em + 0.36rem
);
  border-radius: 0 0.2rem 0.2rem 0;
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.form-control.is-valid {
  background-position: right calc(0.3em + 0.1rem) center;
}

input[type=color].form-control {
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0.09rem 0.225rem;
}

input[type=color].form-control.form-control-sm,
.input-group-sm input[type=color].form-control {
  height: calc(1.2em + 0.36rem + 2px);
  padding: 0.09rem 0.225rem;
}

input[type=color].form-control.form-control-lg,
.input-group-lg input[type=color].form-control {
  height: calc(1.2em + 0.48rem + 2px);
  padding: 0.09rem 0.225rem;
}

input[type=color].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65;
}

.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: -1px;
}

.input-group > .custom-range:focus {
  z-index: 3;
}

.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-range {
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.2em + 0.4rem + 2px);
  border-radius: 0.25rem;
}

.input-group > .custom-range:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.input-group > .custom-range:disabled,
.input-group > .custom-range[readonly] {
  background-color: #e9ecef;
}

.input-group-lg > .custom-range {
  height: calc(1.2em + 0.48rem + 2px);
  padding: 0 0.6rem;
  border-radius: 0.3rem;
}

.input-group-sm > .custom-range {
  height: calc(1.2em + 0.36rem + 2px);
  padding: 0 0.45rem;
  border-radius: 0.2rem;
}

.was-validated .input-group .custom-range:valid,
.input-group .custom-range.is-valid {
  border-color: #38c172;
}

.was-validated .input-group .custom-range:valid:focus,
.input-group .custom-range.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb,
.custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}

.was-validated .custom-range:valid:focus::-moz-range-thumb,
.custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}

.was-validated .custom-range:valid:focus::-ms-thumb,
.custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}

.was-validated .custom-range:valid::-webkit-slider-thumb,
.custom-range.is-valid::-webkit-slider-thumb {
  background-color: #38c172;
  background-image: none;
}

.was-validated .custom-range:valid::-webkit-slider-thumb:active,
.custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}

.was-validated .custom-range:valid::-webkit-slider-runnable-track,
.custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(56, 193, 114, 0.35);
}

.was-validated .custom-range:valid::-moz-range-thumb,
.custom-range.is-valid::-moz-range-thumb {
  background-color: #38c172;
  background-image: none;
}

.was-validated .custom-range:valid::-moz-range-thumb:active,
.custom-range.is-valid::-moz-range-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}

.was-validated .custom-range:valid::-moz-range-track,
.custom-range.is-valid::-moz-range-track {
  background: rgba(56, 193, 114, 0.35);
}

.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip,
.custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-range:valid::-ms-thumb,
.custom-range.is-valid::-ms-thumb {
  background-color: #38c172;
  background-image: none;
}

.was-validated .custom-range:valid::-ms-thumb:active,
.custom-range.is-valid::-ms-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}

.was-validated .custom-range:valid::-ms-track-lower,
.custom-range.is-valid::-ms-track-lower {
  background: rgba(56, 193, 114, 0.35);
}

.was-validated .custom-range:valid::-ms-track-upper,
.custom-range.is-valid::-ms-track-upper {
  background: rgba(56, 193, 114, 0.35);
}

.was-validated .input-group .custom-range:invalid,
.input-group .custom-range.is-invalid {
  border-color: #e3342f;
}

.was-validated .input-group .custom-range:invalid:focus,
.input-group .custom-range.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb,
.custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}

.was-validated .custom-range:invalid:focus::-moz-range-thumb,
.custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}

.was-validated .custom-range:invalid:focus::-ms-thumb,
.custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}

.was-validated .custom-range:invalid::-webkit-slider-thumb,
.custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #e3342f;
  background-image: none;
}

.was-validated .custom-range:invalid::-webkit-slider-thumb:active,
.custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}

.was-validated .custom-range:invalid::-webkit-slider-runnable-track,
.custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(227, 52, 47, 0.35);
}

.was-validated .custom-range:invalid::-moz-range-thumb,
.custom-range.is-invalid::-moz-range-thumb {
  background-color: #e3342f;
  background-image: none;
}

.was-validated .custom-range:invalid::-moz-range-thumb:active,
.custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}

.was-validated .custom-range:invalid::-moz-range-track,
.custom-range.is-invalid::-moz-range-track {
  background: rgba(227, 52, 47, 0.35);
}

.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip,
.custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-range:invalid::-ms-thumb,
.custom-range.is-invalid::-ms-thumb {
  background-color: #e3342f;
  background-image: none;
}

.was-validated .custom-range:invalid::-ms-thumb:active,
.custom-range.is-invalid::-ms-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}

.was-validated .custom-range:invalid::-ms-track-lower,
.custom-range.is-invalid::-ms-track-lower {
  background: rgba(227, 52, 47, 0.35);
}

.was-validated .custom-range:invalid::-ms-track-upper,
.custom-range.is-invalid::-ms-track-upper {
  background: rgba(227, 52, 47, 0.35);
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 0.6875rem;
  line-height: 1.2;
  padding-left: 1.875rem;
}

.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: -0.2125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: -0.2125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat 50%/50% 50%;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.48125rem;
  line-height: 1.2;
  padding-left: 1.3125rem;
}

.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: -0.14875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}

.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: -0.14875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background: no-repeat 50%/50% 50%;
}

.b-rating {
  text-align: center;
}

.b-rating.d-inline-flex {
  width: auto;
}

.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.25em;
}

.b-rating .b-rating-value {
  min-width: 2.5em;
}

.b-rating .b-rating-star {
  display: inline-flex;
  justify-content: center;
  outline: 0;
}

.b-rating .b-rating-star .b-rating-icon {
  display: inline-flex;
  transition: all 0.15s ease-in-out;
}

.b-rating.disabled,
.b-rating:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

.b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}

.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
.b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}

.b-rating[dir=rtl] .b-rating-star-half {
  transform: scale(-1, 1);
}

.b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}

[dir=rtl] .b-form-spinbutton:not(.flex-column),
.b-form-spinbutton[dir=rtl]:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-form-spinbutton output {
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0 0.25rem;
}

.b-form-spinbutton output > div,
.b-form-spinbutton output > bdi {
  display: block;
  min-width: 2.25em;
  height: 1.2em;
}

.b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}

.b-form-spinbutton.flex-column output {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}

.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}

.b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}

.b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}

.b-form-spinbutton .btn:disabled {
  pointer-events: none;
}

.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}

.b-form-spinbutton.disabled,
.b-form-spinbutton.readonly {
  background-color: #e9ecef;
}

.b-form-spinbutton.disabled {
  pointer-events: none;
}

.b-form-tags.focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.b-form-tags.focus.is-valid {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.b-form-tags.focus.is-invalid {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.b-form-tags.disabled {
  background-color: #e9ecef;
}

.b-form-tags-list {
  margin-top: -0.25rem;
}

.b-form-tags-list .b-form-tags-field,
.b-form-tags-list .b-form-tag {
  margin-top: 0.25rem;
}

.b-form-tags-input {
  color: #495057;
}

.b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.2;
  margin-right: 0.25rem;
}

.b-form-tag.disabled {
  opacity: 0.75;
}

.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: 0.25rem;
}

.form-control-sm .b-form-tag {
  line-height: 1.2;
}

.form-control-lg .b-form-tag {
  line-height: 1.2;
}

.media-aside {
  display: flex;
  margin-right: 1rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}

.modal-backdrop {
  opacity: 0.5;
}

.b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}

.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}

.popover.b-popover {
  display: block;
  opacity: 1;
  outline: 0;
}

.popover.b-popover.fade:not(.show) {
  opacity: 0;
}

.popover.b-popover.show {
  opacity: 1;
}

.b-popover-primary.popover {
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}

.b-popover-primary.bs-popover-top > .arrow::before,
.b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c6e0f5;
}

.b-popover-primary.bs-popover-top > .arrow::after,
.b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d6e9f8;
}

.b-popover-primary.bs-popover-right > .arrow::before,
.b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c6e0f5;
}

.b-popover-primary.bs-popover-right > .arrow::after,
.b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d6e9f8;
}

.b-popover-primary.bs-popover-bottom > .arrow::before,
.b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c6e0f5;
}

.b-popover-primary.bs-popover-bottom > .arrow::after,
.b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #c9e2f6;
}

.b-popover-primary.bs-popover-bottom .popover-header::before,
.b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #c9e2f6;
}

.b-popover-primary.bs-popover-left > .arrow::before,
.b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c6e0f5;
}

.b-popover-primary.bs-popover-left > .arrow::after,
.b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d6e9f8;
}

.b-popover-primary .popover-header {
  color: #212529;
  background-color: #c9e2f6;
  border-bottom-color: #b3d6f2;
}

.b-popover-primary .popover-body {
  color: #1b4b72;
}

.b-popover-secondary.popover {
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.b-popover-secondary.bs-popover-top > .arrow::before,
.b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #d6d8db;
}

.b-popover-secondary.bs-popover-top > .arrow::after,
.b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #e2e3e5;
}

.b-popover-secondary.bs-popover-right > .arrow::before,
.b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #d6d8db;
}

.b-popover-secondary.bs-popover-right > .arrow::after,
.b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #e2e3e5;
}

.b-popover-secondary.bs-popover-bottom > .arrow::before,
.b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #d6d8db;
}

.b-popover-secondary.bs-popover-bottom > .arrow::after,
.b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #dadbde;
}

.b-popover-secondary.bs-popover-bottom .popover-header::before,
.b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #dadbde;
}

.b-popover-secondary.bs-popover-left > .arrow::before,
.b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #d6d8db;
}

.b-popover-secondary.bs-popover-left > .arrow::after,
.b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #e2e3e5;
}

.b-popover-secondary .popover-header {
  color: #212529;
  background-color: #dadbde;
  border-bottom-color: #ccced2;
}

.b-popover-secondary .popover-body {
  color: #383d41;
}

.b-popover-success.popover {
  background-color: #d7f3e3;
  border-color: #c7eed8;
}

.b-popover-success.bs-popover-top > .arrow::before,
.b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c7eed8;
}

.b-popover-success.bs-popover-top > .arrow::after,
.b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d7f3e3;
}

.b-popover-success.bs-popover-right > .arrow::before,
.b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c7eed8;
}

.b-popover-success.bs-popover-right > .arrow::after,
.b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d7f3e3;
}

.b-popover-success.bs-popover-bottom > .arrow::before,
.b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c7eed8;
}

.b-popover-success.bs-popover-bottom > .arrow::after,
.b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #cbefdb;
}

.b-popover-success.bs-popover-bottom .popover-header::before,
.b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #cbefdb;
}

.b-popover-success.bs-popover-left > .arrow::before,
.b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c7eed8;
}

.b-popover-success.bs-popover-left > .arrow::after,
.b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d7f3e3;
}

.b-popover-success .popover-header {
  color: #212529;
  background-color: #cbefdb;
  border-bottom-color: #b8eacd;
}

.b-popover-success .popover-body {
  color: #1d643b;
}

.b-popover-info.popover {
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}

.b-popover-info.bs-popover-top > .arrow::before,
.b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #d6e9f9;
}

.b-popover-info.bs-popover-top > .arrow::after,
.b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #e2f0fb;
}

.b-popover-info.bs-popover-right > .arrow::before,
.b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #d6e9f9;
}

.b-popover-info.bs-popover-right > .arrow::after,
.b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #e2f0fb;
}

.b-popover-info.bs-popover-bottom > .arrow::before,
.b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #d6e9f9;
}

.b-popover-info.bs-popover-bottom > .arrow::after,
.b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #d5e9f9;
}

.b-popover-info.bs-popover-bottom .popover-header::before,
.b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #d5e9f9;
}

.b-popover-info.bs-popover-left > .arrow::before,
.b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #d6e9f9;
}

.b-popover-info.bs-popover-left > .arrow::after,
.b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #e2f0fb;
}

.b-popover-info .popover-header {
  color: #212529;
  background-color: #d5e9f9;
  border-bottom-color: #beddf6;
}

.b-popover-info .popover-body {
  color: #385d7a;
}

.b-popover-warning.popover {
  background-color: #fffbdb;
  border-color: #fffacc;
}

.b-popover-warning.bs-popover-top > .arrow::before,
.b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fffacc;
}

.b-popover-warning.bs-popover-top > .arrow::after,
.b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fffbdb;
}

.b-popover-warning.bs-popover-right > .arrow::before,
.b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fffacc;
}

.b-popover-warning.bs-popover-right > .arrow::after,
.b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fffbdb;
}

.b-popover-warning.bs-popover-bottom > .arrow::before,
.b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fffacc;
}

.b-popover-warning.bs-popover-bottom > .arrow::after,
.b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fff9cc;
}

.b-popover-warning.bs-popover-bottom .popover-header::before,
.b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fff9cc;
}

.b-popover-warning.bs-popover-left > .arrow::before,
.b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fffacc;
}

.b-popover-warning.bs-popover-left > .arrow::after,
.b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fffbdb;
}

.b-popover-warning .popover-header {
  color: #212529;
  background-color: #fff9cc;
  border-bottom-color: #fff6b2;
}

.b-popover-warning .popover-body {
  color: #857b26;
}

.b-popover-danger.popover {
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}

.b-popover-danger.bs-popover-top > .arrow::before,
.b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #f7c6c5;
}

.b-popover-danger.bs-popover-top > .arrow::after,
.b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #f9d6d5;
}

.b-popover-danger.bs-popover-right > .arrow::before,
.b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #f7c6c5;
}

.b-popover-danger.bs-popover-right > .arrow::after,
.b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #f9d6d5;
}

.b-popover-danger.bs-popover-bottom > .arrow::before,
.b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #f7c6c5;
}

.b-popover-danger.bs-popover-bottom > .arrow::after,
.b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #f7c9c8;
}

.b-popover-danger.bs-popover-bottom .popover-header::before,
.b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #f7c9c8;
}

.b-popover-danger.bs-popover-left > .arrow::before,
.b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #f7c6c5;
}

.b-popover-danger.bs-popover-left > .arrow::after,
.b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #f9d6d5;
}

.b-popover-danger .popover-header {
  color: #212529;
  background-color: #f7c9c8;
  border-bottom-color: #f4b3b1;
}

.b-popover-danger .popover-body {
  color: #761b18;
}

.b-popover-light.popover {
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.b-popover-light.bs-popover-top > .arrow::before,
.b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fdfdfe;
}

.b-popover-light.bs-popover-top > .arrow::after,
.b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fefefe;
}

.b-popover-light.bs-popover-right > .arrow::before,
.b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fdfdfe;
}

.b-popover-light.bs-popover-right > .arrow::after,
.b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fefefe;
}

.b-popover-light.bs-popover-bottom > .arrow::before,
.b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fdfdfe;
}

.b-popover-light.bs-popover-bottom > .arrow::after,
.b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #f6f6f6;
}

.b-popover-light.bs-popover-bottom .popover-header::before,
.b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #f6f6f6;
}

.b-popover-light.bs-popover-left > .arrow::before,
.b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fdfdfe;
}

.b-popover-light.bs-popover-left > .arrow::after,
.b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fefefe;
}

.b-popover-light .popover-header {
  color: #212529;
  background-color: #f6f6f6;
  border-bottom-color: #eaeaea;
}

.b-popover-light .popover-body {
  color: #818182;
}

.b-popover-dark.popover {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.b-popover-dark.bs-popover-top > .arrow::before,
.b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c6c8ca;
}

.b-popover-dark.bs-popover-top > .arrow::after,
.b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d6d8d9;
}

.b-popover-dark.bs-popover-right > .arrow::before,
.b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c6c8ca;
}

.b-popover-dark.bs-popover-right > .arrow::after,
.b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d6d8d9;
}

.b-popover-dark.bs-popover-bottom > .arrow::before,
.b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c6c8ca;
}

.b-popover-dark.bs-popover-bottom > .arrow::after,
.b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #ced0d2;
}

.b-popover-dark.bs-popover-bottom .popover-header::before,
.b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #ced0d2;
}

.b-popover-dark.bs-popover-left > .arrow::before,
.b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c6c8ca;
}

.b-popover-dark.bs-popover-left > .arrow::after,
.b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d6d8d9;
}

.b-popover-dark .popover-header {
  color: #212529;
  background-color: #ced0d2;
  border-bottom-color: #c1c4c5;
}

.b-popover-dark .popover-body {
  color: #1b1e21;
}

.b-sidebar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: calc(1030 + 5);
}

.b-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}

.b-sidebar.slide {
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .b-sidebar.slide {
    transition: none;
  }
}

.b-sidebar:not(.b-sidebar-right) {
  left: 0;
  right: auto;
}

.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
  transform: translateX(-100%);
}

.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
  margin-left: auto;
}

.b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
}

.b-sidebar.b-sidebar-right.slide:not(.show) {
  transform: translateX(100%);
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
}

.b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

[dir=rtl] .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
}

.b-sidebar > .b-sidebar-body {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.b-sidebar > .b-sidebar-footer {
  flex-grow: 0;
}

.b-skeleton-wrapper {
  cursor: wait;
}

.b-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  mask-image: radial-gradient(white, black);
}

.b-skeleton::before {
  content: " ";
}

.b-skeleton-text {
  height: 0.55rem;
  margin-bottom: 0.1875rem;
  border-radius: 0.25rem;
}

.b-skeleton-button {
  width: 75px;
  padding: 0.2rem 0.5rem;
  font-size: 0.55rem;
  line-height: 1.2;
  border-radius: 0.25rem;
}

.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.b-skeleton-input {
  height: calc(1.2em + 0.4rem + 2px);
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  border: #ced4da solid 1px;
  border-radius: 0.25rem;
}

.b-skeleton-icon-wrapper svg {
  color: rgba(0, 0, 0, 0.12);
}

.b-skeleton-img {
  height: 100%;
  width: 100%;
}

.b-skeleton-animate-wave::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
  animation: b-skeleton-animate-wave 1.75s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-wave::after {
    background: none;
    animation: none;
  }
}

@keyframes b-skeleton-animate-wave {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.b-skeleton-animate-fade {
  animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-fade {
    animation: none;
  }
}

@keyframes b-skeleton-animate-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.b-skeleton-animate-throb {
  animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
}

@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-throb {
    animation: none;
  }
}

@keyframes b-skeleton-animate-throb {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.975);
  }
}

.table.b-table.b-table-fixed {
  table-layout: fixed;
}

.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}

.table.b-table[aria-busy=true] {
  opacity: 0.55;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}

.table.b-table > caption {
  caption-side: bottom;
}

.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}

.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table.b-table.table-hover > tbody > tr.table-active:hover td,
.table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #212529;
  background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
  background-repeat: no-repeat;
}

.table.b-table > tbody > .bg-active,
.table.b-table > tbody > .bg-active > th,
.table.b-table > tbody > .bg-active > td {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td,
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
  background-repeat: no-repeat;
}

.b-table-sticky-header,
.table-responsive,
[class*=table-responsive-] {
  margin-bottom: 0.75rem;
}

.b-table-sticky-header > .table,
.table-responsive > .table,
[class*=table-responsive-] > .table {
  margin-bottom: 0;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}

@media print {
  .b-table-sticky-header {
    overflow-y: visible !important;
    max-height: none !important;
  }
}

@supports (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
  }

  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
  }

  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    color: #212529;
    background-color: #f8fafc;
  }

  .table.b-table.table-dark > thead > tr > .bg-b-table-default,
  .table.b-table.table-dark > tbody > tr > .bg-b-table-default,
  .table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40;
  }

  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-repeat: no-repeat;
  }

  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat;
  }

  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #212529;
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat;
  }

  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat;
  }
}

.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}

.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.5em / 2) center;
  padding-right: calc(0.5em + 0.65em);
}

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.5em / 2) center;
  padding-left: calc(0.5em + 0.65em);
}

.table.b-table > thead > tr > [aria-sort=none],
.table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > [aria-sort=ascending],
.table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > [aria-sort=descending],
.table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort=none],
.table.b-table.table-dark > tfoot > tr > [aria-sort=none],
.table.b-table > .thead-dark > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort=ascending],
.table.b-table.table-dark > tfoot > tr > [aria-sort=ascending],
.table.b-table > .thead-dark > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-dark > thead > tr > [aria-sort=descending],
.table.b-table.table-dark > tfoot > tr > [aria-sort=descending],
.table.b-table > .thead-dark > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort=none],
.table.b-table > tfoot > tr > .table-dark[aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort=ascending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table > thead > tr > .table-dark[aria-sort=descending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}

.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em);
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  user-select: none;
}

.b-time {
  min-width: 150px;
}

.b-time[aria-disabled=true] output,
.b-time[aria-readonly=true] output,
.b-time output.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.b-time[aria-disabled=true] output {
  pointer-events: none;
}

[dir=rtl] .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-time .b-time-header {
  margin-bottom: 0.5rem;
}

.b-time .b-time-header output {
  padding: 0.25rem;
  font-size: 80%;
}

.b-time .b-time-footer {
  margin-top: 0.5rem;
}

.b-time .b-time-ampm {
  margin-left: 0.5rem;
}

.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
  border-radius: 0.25rem;
}

.b-toast .toast {
  background-color: rgba(255, 255, 255, 0.85);
}

.b-toast:not(:last-child) {
  margin-bottom: 0.5rem;
}

.b-toast.b-toast-solid .toast {
  background-color: white;
}

.b-toast .toast {
  opacity: 1;
}

.b-toast .toast.fade:not(.show) {
  opacity: 0;
}

.b-toast .toast .toast-body {
  display: block;
}

.b-toast-primary .toast {
  background-color: rgba(236, 245, 252, 0.85);
  border-color: rgba(198, 224, 245, 0.85);
  color: #1b4b72;
}

.b-toast-primary .toast .toast-header {
  color: #1b4b72;
  background-color: rgba(214, 233, 248, 0.85);
  border-bottom-color: rgba(198, 224, 245, 0.85);
}

.b-toast-primary.b-toast-solid .toast {
  background-color: #ecf5fc;
}

.b-toast-secondary .toast {
  background-color: rgba(239, 240, 241, 0.85);
  border-color: rgba(214, 216, 219, 0.85);
  color: #383d41;
}

.b-toast-secondary .toast .toast-header {
  color: #383d41;
  background-color: rgba(226, 227, 229, 0.85);
  border-bottom-color: rgba(214, 216, 219, 0.85);
}

.b-toast-secondary.b-toast-solid .toast {
  background-color: #eff0f1;
}

.b-toast-success .toast {
  background-color: rgba(235, 249, 241, 0.85);
  border-color: rgba(199, 238, 216, 0.85);
  color: #1d643b;
}

.b-toast-success .toast .toast-header {
  color: #1d643b;
  background-color: rgba(215, 243, 227, 0.85);
  border-bottom-color: rgba(199, 238, 216, 0.85);
}

.b-toast-success.b-toast-solid .toast {
  background-color: #ebf9f1;
}

.b-toast-info .toast {
  background-color: rgba(248, 252, 254, 0.85);
  border-color: rgba(214, 233, 249, 0.85);
  color: #385d7a;
}

.b-toast-info .toast .toast-header {
  color: #385d7a;
  background-color: rgba(226, 240, 251, 0.85);
  border-bottom-color: rgba(214, 233, 249, 0.85);
}

.b-toast-info.b-toast-solid .toast {
  background-color: #f8fcfe;
}

.b-toast-warning .toast {
  background-color: rgba(255, 254, 245, 0.85);
  border-color: rgba(255, 250, 204, 0.85);
  color: #857b26;
}

.b-toast-warning .toast .toast-header {
  color: #857b26;
  background-color: rgba(255, 251, 219, 0.85);
  border-bottom-color: rgba(255, 250, 204, 0.85);
}

.b-toast-warning.b-toast-solid .toast {
  background-color: #fffef5;
}

.b-toast-danger .toast {
  background-color: rgba(252, 236, 235, 0.85);
  border-color: rgba(247, 198, 197, 0.85);
  color: #761b18;
}

.b-toast-danger .toast .toast-header {
  color: #761b18;
  background-color: rgba(249, 214, 213, 0.85);
  border-bottom-color: rgba(247, 198, 197, 0.85);
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fceceb;
}

.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(253, 253, 254, 0.85);
  color: #818182;
}

.b-toast-light .toast .toast-header {
  color: #818182;
  background-color: rgba(254, 254, 254, 0.85);
  border-bottom-color: rgba(253, 253, 254, 0.85);
}

.b-toast-light.b-toast-solid .toast {
  background-color: white;
}

.b-toast-dark .toast {
  background-color: rgba(227, 229, 229, 0.85);
  border-color: rgba(198, 200, 202, 0.85);
  color: #1b1e21;
}

.b-toast-dark .toast .toast-header {
  color: #1b1e21;
  background-color: rgba(214, 216, 217, 0.85);
  border-bottom-color: rgba(198, 200, 202, 0.85);
}

.b-toast-dark.b-toast-solid .toast {
  background-color: #e3e5e5;
}

.b-toaster {
  z-index: 1100;
}

.b-toaster .b-toaster-slot {
  position: relative;
  display: block;
}

.b-toaster .b-toaster-slot:empty {
  display: none !important;
}

.b-toaster.b-toaster-top-right,
.b-toaster.b-toaster-top-left,
.b-toaster.b-toaster-top-center,
.b-toaster.b-toaster-top-full,
.b-toaster.b-toaster-bottom-right,
.b-toaster.b-toaster-bottom-left,
.b-toaster.b-toaster-bottom-center,
.b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
}

.b-toaster.b-toaster-top-right .b-toaster-slot,
.b-toaster.b-toaster-top-left .b-toaster-slot,
.b-toaster.b-toaster-top-center .b-toaster-slot,
.b-toaster.b-toaster-top-full .b-toaster-slot,
.b-toaster.b-toaster-bottom-right .b-toaster-slot,
.b-toaster.b-toaster-bottom-left .b-toaster-slot,
.b-toaster.b-toaster-bottom-center .b-toaster-slot,
.b-toaster.b-toaster-bottom-full .b-toaster-slot {
  position: absolute;
  max-width: 350px;
  width: 100%;
  /* IE 11 fix */
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

.b-toaster.b-toaster-top-full .b-toaster-slot,
.b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%;
}

.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-top-full .b-toaster-slot .toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%;
}

.b-toaster.b-toaster-top-right,
.b-toaster.b-toaster-top-left,
.b-toaster.b-toaster-top-center,
.b-toaster.b-toaster-top-full {
  top: 0;
}

.b-toaster.b-toaster-top-right .b-toaster-slot,
.b-toaster.b-toaster-top-left .b-toaster-slot,
.b-toaster.b-toaster-top-center .b-toaster-slot,
.b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5rem;
}

.b-toaster.b-toaster-bottom-right,
.b-toaster.b-toaster-bottom-left,
.b-toaster.b-toaster-bottom-center,
.b-toaster.b-toaster-bottom-full {
  bottom: 0;
}

.b-toaster.b-toaster-bottom-right .b-toaster-slot,
.b-toaster.b-toaster-bottom-left .b-toaster-slot,
.b-toaster.b-toaster-bottom-center .b-toaster-slot,
.b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5rem;
}

.b-toaster.b-toaster-top-right .b-toaster-slot,
.b-toaster.b-toaster-bottom-right .b-toaster-slot,
.b-toaster.b-toaster-top-center .b-toaster-slot,
.b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}

.b-toaster.b-toaster-top-left .b-toaster-slot,
.b-toaster.b-toaster-bottom-left .b-toaster-slot,
.b-toaster.b-toaster-top-center .b-toaster-slot,
.b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active,
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-top-right .b-toast.b-toaster-move,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-move,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform 0.175s;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade,
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade,
.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade,
.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade,
.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s;
}

.tooltip.b-tooltip {
  display: block;
  opacity: 0.9;
  outline: 0;
}

.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0;
}

.tooltip.b-tooltip.show {
  opacity: 0.9;
}

.tooltip.b-tooltip.noninteractive {
  pointer-events: none;
}

.tooltip.b-tooltip .arrow {
  margin: 0 0.25rem;
}

.tooltip.b-tooltip.bs-tooltip-right .arrow,
.tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip.b-tooltip.bs-tooltip-left .arrow,
.tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow {
  margin: 0.25rem 0;
}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #3490dc;
}

.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #3490dc;
}

.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #3490dc;
}

.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #3490dc;
}

.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #3490dc;
}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #6c757d;
}

.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #6c757d;
}

.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #6c757d;
}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #38c172;
}

.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #38c172;
}

.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #38c172;
}

.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #38c172;
}

.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #38c172;
}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #6cb2eb;
}

.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #6cb2eb;
}

.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #6cb2eb;
}

.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #6cb2eb;
}

.tooltip.b-tooltip-info .tooltip-inner {
  color: #212529;
  background-color: #6cb2eb;
}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #ffed4a;
}

.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #ffed4a;
}

.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #ffed4a;
}

.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #ffed4a;
}

.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #ffed4a;
}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #e3342f;
}

.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #e3342f;
}

.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #e3342f;
}

.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #e3342f;
}

.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #e3342f;
}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f8f9fa;
}

.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f8f9fa;
}

.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #f8f9fa;
}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #343a40;
}

.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before,
.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #343a40;
}

.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #343a40;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}

.b-icon.b-icon-animation-cylon,
.b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon,
  .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-cylon-vertical,
.b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical,
  .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-fade,
.b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade,
  .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin,
.b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s infinite linear normal b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin,
  .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-reverse,
.b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse,
  .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-pulse,
.b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse,
  .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-spin-reverse-pulse,
.b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse,
  .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}

.b-icon.b-icon-animation-throb,
.b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}

@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb,
  .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }

  100% {
    transform: translateY(-25%);
  }
}

@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

.btn.btn-basic,
.btn-basic {
  color: #212529;
  background-color: #eee;
  border-color: #ddd;
  color: #000;
}

.btn.btn-basic:hover,
.btn-basic:hover {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
}

.btn.btn-basic:focus,
.btn.btn-basic.focus,
.btn-basic:focus,
.btn-basic.focus {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.btn.btn-basic.disabled,
.btn.btn-basic:disabled,
.btn-basic.disabled,
.btn-basic:disabled {
  color: #212529;
  background-color: #eee;
  border-color: #ddd;
}

.btn.btn-basic:not(:disabled):not(.disabled):active,
.btn.btn-basic:not(:disabled):not(.disabled).active,
.show > .btn.btn-basic.dropdown-toggle,
.btn-basic:not(:disabled):not(.disabled):active,
.btn-basic:not(:disabled):not(.disabled).active,
.show > .btn-basic.dropdown-toggle {
  color: #212529;
  background-color: #ccc;
  border-color: #aaa;
}

.btn.btn-basic:not(:disabled):not(.disabled):active:focus,
.btn.btn-basic:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-basic.dropdown-toggle:focus,
.btn-basic:not(:disabled):not(.disabled):active:focus,
.btn-basic:not(:disabled):not(.disabled).active:focus,
.show > .btn-basic.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.btn.btn-morado,
.btn-morado {
  color: #fff;
  background-color: #874c97;
  border-color: #683a75;
  color: #fff;
}

.btn.btn-morado:hover,
.btn-morado:hover {
  color: #212529;
  background-color: #ccabd5;
  border-color: #d6bcdd;
}

.btn.btn-morado:focus,
.btn.btn-morado.focus,
.btn-morado:focus,
.btn-morado.focus {
  color: #212529;
  background-color: #ccabd5;
  border-color: #d6bcdd;
  box-shadow: 0 0 0 0.2rem rgba(127, 88, 138, 0.5);
}

.btn.btn-morado.disabled,
.btn.btn-morado:disabled,
.btn-morado.disabled,
.btn-morado:disabled {
  color: #fff;
  background-color: #874c97;
  border-color: #683a75;
}

.btn.btn-morado:not(:disabled):not(.disabled):active,
.btn.btn-morado:not(:disabled):not(.disabled).active,
.show > .btn.btn-morado.dropdown-toggle,
.btn-morado:not(:disabled):not(.disabled):active,
.btn-morado:not(:disabled):not(.disabled).active,
.show > .btn-morado.dropdown-toggle {
  color: #fff;
  background-color: #3e2345;
  border-color: #5f356a;
}

.btn.btn-morado:not(:disabled):not(.disabled):active:focus,
.btn.btn-morado:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-morado.dropdown-toggle:focus,
.btn-morado:not(:disabled):not(.disabled):active:focus,
.btn-morado:not(:disabled):not(.disabled).active:focus,
.show > .btn-morado.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 88, 138, 0.5);
}

.loading {
  position: relative;
  /*
      -webkit-transition: all 1s linear;
      -moz-transition: all 1s linear;
      -o-transition: all 1s linear;
      transition: all 1s linear;
      */
}

.loading * {
  pointer-events: none;
}

.loading:after {
  content: " ";
  display: block;
  z-index: 1;
  background: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10%;
  background-image: url(/images/loading.webp);
  /*// background-blend-mode: exclusion; */
  background-blend-mode: multiply;
  background-color: rgb(255, 255, 255);
}

/*
.loading:before {
    content: " ";
    display: block;
    z-index: 98;
    background: white;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0.7;

}
*/

@keyframes ldio-obuy3ojugfb {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.loading.loading-bar .ldio-obuy3ojugfb div,
.loading .loading-bar .ldio-obuy3ojugfb div {
  position: absolute;
  width: 16px;
  height: 80px;
  top: 60px;
  animation: ldio-obuy3ojugfb 1.8867924528s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading.loading-bar .ldio-obuy3ojugfb div:nth-child(1),
.loading .loading-bar .ldio-obuy3ojugfb div:nth-child(1) {
  transform: translate(32px, 0);
  background: #3e6d8d;
  animation-delay: -1.1320754717s;
}

.loading.loading-bar .ldio-obuy3ojugfb div:nth-child(2),
.loading .loading-bar .ldio-obuy3ojugfb div:nth-child(2) {
  transform: translate(72px, 0);
  background: #4b9bbe;
  animation-delay: -0.7547169811s;
}

.loading.loading-bar .ldio-obuy3ojugfb div:nth-child(3),
.loading .loading-bar .ldio-obuy3ojugfb div:nth-child(3) {
  transform: translate(112px, 0);
  background: #f9aa47;
  animation-delay: -0.3773584906s;
}

.loading.loading-bar .ldio-obuy3ojugfb div:nth-child(4),
.loading .loading-bar .ldio-obuy3ojugfb div:nth-child(4) {
  transform: translate(152px, 0);
  background: #c93228;
  animation-delay: -1.8867924528s;
}

.loading.loading-bar .loadingio-spinner-bars-vggdxohuuib,
.loading .loading-bar .loadingio-spinner-bars-vggdxohuuib {
  display: none;
}

.loading.loading-bar > .loadingio-spinner-bars-vggdxohuuib,
.loading .loading-bar > .loadingio-spinner-bars-vggdxohuuib {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.loading.loading-bar .ldio-obuy3ojugfb,
.loading .loading-bar .ldio-obuy3ojugfb {
  width: 200px;
  height: 200px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  backface-visibility: hidden;
  /*
  transform-origin: 0 0;
   see note above */
}

.loading.loading-bar .ldio-obuy3ojugfb div,
.loading .loading-bar .ldio-obuy3ojugfb div {
  box-sizing: content-box;
}

::placeholder,
::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: green;
  opacity: 1;
  /* Firefox */
}

.required input,
.required select,
.required {
  background-color: cornsilk !important;
  color: #000 !important;
}

.nivel1 {
  margin-left: 15px;
}

.nivel2 {
  margin-left: 30px;
}

.pagination > label {
  margin: 4px 8px 2px 8px;
}

body {
  margin: 0;
}

.my-widget {
  color: var(--danger);
}

.table_container {
  width: 100%;
  overflow-x: auto;
  padding-top: 10px;
}

div.inactive,
tr.inactive > td {
  filter: saturate(0) opacity(0.8);
  /*
  background-color: #a21d1d;
  color: #fff;
  */
}

div.active {
  border: solid #4e98bb 1px;
}

tr.active {
  /*
  filter: hue-rotate(208deg) invert(1);
  color: #fff;
  */
}

tr.active > td[rowspan=""] {
  background-color: #4e98bb;
}

tr.active > th {
  /*
  filter: hue-rotate(208deg) invert(1);
  color: #fff;
  */
  background-color: #4e98bb;
}

/*
body.noTextSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/

.disabled {
  opacity: 0.6 !important;
  filter: grayscale(100%);
}

.vs__dropdown-menu,
.dropdown-menu {
  min-width: 300px !important;
  max-width: 90vw !important;
}

/* Works on Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: #5f5f5d #cccccc;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #cccccc;
}

*::-webkit-scrollbar-thumb {
  background-color: #5f5f5d;
  border-radius: 20px;
  border: 3px solid #cccccc;
}

.vue-sticky-el.top-sticky {
  margin-top: 48px !important;
  z-index: 1 !important;
}

.vue-sticky-el.top-sticky > * {
  background: #fff;
  padding-top: 6px;
  padding-left: 2px;
}

.form-group,
.btn-group {
  margin-bottom: 0 !important;
}

.table thead th {
  vertical-align: middle;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(22, 69, 116, 0.4509803922);
}

th {
  background: #b0cfed;
}

@font-face {
  font-family: "jnicon";
  src: url("./fonts/jnicon.ttf") format("truetype"), url("./fonts/jnicon.woff") format("woff"), url("./fonts/jnicon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "jnicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reportfile:before {
  content: "\e90b";
}

.icon-att-report:before {
  content: "\eac8";
}

.icon-finance-report:before {
  content: "\eade";
}

.icon-pay:before {
  content: "\eae4";
}

.icon-circle:before {
  content: "\eae5";
}

.icon-support:before {
  content: "\eac9";
  color: #5082ba;
}

.icon-permission:before {
  content: "\eaca";
}

.icon-attendance:before {
  content: "\eacb";
}

.icon-admision:before {
  content: "\eae6";
}

.icon-organization:before {
  content: "\eae7";
}

.icon-box-star:before {
  content: "\eacc";
}

.icon-certificate2:before {
  content: "\eacd";
}

.icon-chart:before {
  content: "\eace";
}

.icon-clothes:before {
  content: "\eacf";
}

.icon-connection:before {
  content: "\ead0";
}

.icon-customer:before {
  content: "\ead1";
}

.icon-desk:before {
  content: "\ead2";
}

.icon-handcuffs:before {
  content: "\ead3";
}

.icon-handshake:before {
  content: "\ead4";
}

.icon-history:before {
  content: "\ead5";
}

.icon-location:before {
  content: "\ead6";
}

.icon-meal:before {
  content: "\ead7";
}

.icon-money-bag:before {
  content: "\ead8";
}

.icon-plants:before {
  content: "\ead9";
}

.icon-point:before {
  content: "\eada";
}

.icon-restroom:before {
  content: "\eadb";
}

.icon-ribbon:before {
  content: "\eadc";
}

.icon-vest:before {
  content: "\eae0";
}

.icon-appid:before {
  content: "\ea9e";
}

.icon-basket:before {
  content: "\ea9d";
}

.icon-bed:before {
  content: "\ea9c";
}

.icon-biometric:before {
  content: "\e98f";
}

.icon-books-h:before {
  content: "\ea9f";
}

.icon-contract:before {
  content: "\eaa0";
}

.icon-presentation:before {
  content: "\eaa1";
}

.icon-room-service:before {
  content: "\eaa2";
}

.icon-suitcase:before {
  content: "\eaa3";
}

.icon-tea:before {
  content: "\eac7";
}

.icon-audio:before {
  content: "\e97c";
}

.icon-audio-off:before {
  content: "\e97d";
}

.icon-delete:before {
  content: "\e97e";
}

.icon-disabled-user:before {
  content: "\e981";
}

.icon-disabled-users:before {
  content: "\e982";
}

.icon-user-admin:before {
  content: "\e983";
}

.icon-verified:before {
  content: "\e984";
  color: #0082c9;
}

.icon-verify:before {
  content: "\e98b";
  color: #969696;
}

.icon-verifying:before {
  content: "\e98c";
  color: #0082c9;
}

.icon-cart-empty .path1:before {
  content: "\e9a5";
  color: rgb(0, 150, 136);
}

.icon-cart-empty .path2:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-empty .path3:before {
  content: "\eae9";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-empty .path4:before {
  content: "\eaea";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-empty .path5:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-empty .path6:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-empty .path7:before {
  content: "\eaed";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-add .path1:before {
  content: "\e9a7";
  color: rgb(0, 150, 136);
}

.icon-cart-add .path2:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-add .path3:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-add .path4:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-add .path5:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-add .path6:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-add .path7:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-add .path8:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}

.icon-cart-add .path9:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}

.icon-cart-buying .path1:before {
  content: "\e9b2";
  color: rgb(245, 124, 0);
}

.icon-cart-buying .path2:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}

.icon-cart-buying .path3:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-buying .path4:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-buying .path5:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-buying .path6:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-buying .path7:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-buying .path8:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-buying .path9:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-checkout .path1:before {
  content: "\e9c0";
  color: rgb(63, 81, 181);
}

.icon-cart-checkout .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-checkout .path3:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-checkout .path4:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-checkout .path5:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-checkout .path6:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-checkout .path7:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-checkout .path8:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-return .path1:before {
  content: "\e9d0";
  color: rgb(0, 150, 136);
}

.icon-cart-return .path2:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-return .path3:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-return .path4:before {
  content: "\eaf0";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-return .path5:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-return .path6:before {
  content: "\eaf2";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-return .path7:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-return .path8:before {
  content: "\eaf4";
  margin-left: -1em;
  color: rgb(170, 0, 255);
}

.icon-cart-sell .path1:before {
  content: "\e9dd";
  color: rgb(55, 71, 79);
}

.icon-cart-sell .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-sell .path3:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-sell .path4:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-sell .path5:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-sell .path6:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-sell .path7:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-sell .path8:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}

.icon-cart-sell .path9:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}

.icon-cart-sell-history .path1:before {
  content: "\e9e6";
  color: rgb(245, 124, 0);
}

.icon-cart-sell-history .path2:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 152, 0);
}

.icon-cart-sell-history .path3:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-sell-history .path4:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}

.icon-cart-sell-history .path5:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(0, 105, 92);
}

.icon-cart-sell-history .path6:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-sell-history .path7:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-cart-sell-history .path8:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-sell-history .path9:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-cart-sell-history .path10:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(0, 172, 193);
}

.icon-cart-sell-history .path11:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}

.icon-cart-sell-history .path12:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-clientes:before {
  content: "\ea7d";
}

.icon-contrato .path1:before {
  content: "\ea85";
  color: rgb(144, 202, 249);
}

.icon-contrato .path2:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(225, 245, 254);
}

.icon-contrato .path3:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-contrato .path4:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-contrato .path5:before {
  content: "\eb0b";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-contrato .path6:before {
  content: "\eb0c";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-contrato .path7:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-exchange .path1:before {
  content: "\ea86";
  color: rgb(189, 189, 189);
}

.icon-exchange .path2:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(189, 189, 189);
}

.icon-exchange .path3:before {
  content: "\eb0f";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}

.icon-exchange .path4:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(238, 238, 238);
}

.icon-exchange .path5:before {
  content: "\eb11";
  margin-left: -1em;
  color: rgb(46, 125, 50);
}

.icon-email .path1:before {
  content: "\ea87";
  color: rgb(21, 101, 192);
}

.icon-email .path2:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}

.icon-faq .path1:before {
  content: "\ea88";
  color: rgb(85, 139, 47);
}

.icon-faq .path2:before {
  content: "\eb13";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-faq .path3:before {
  content: "\eb14";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}

.icon-faq .path4:before {
  content: "\eb15";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-guru .path1:before {
  content: "\ea89";
  color: rgb(255, 183, 77);
}

.icon-guru .path2:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(255, 117, 76);
}

.icon-guru .path3:before {
  content: "\eb17";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}

.icon-guru .path4:before {
  content: "\eb18";
  margin-left: -1em;
  color: rgb(255, 117, 76);
}

.icon-guru .path5:before {
  content: "\eb19";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}

.icon-guru .path6:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}

.icon-guru .path7:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}

.icon-guru .path8:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(230, 74, 25);
}

.icon-id-card .path1:before {
  content: "\ea8a";
  color: rgb(74, 117, 195);
}

.icon-id-card .path2:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(44, 66, 144);
}

.icon-id-card .path3:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(255, 214, 82);
}

.icon-id-card .path4:before {
  content: "\eb21";
  margin-left: -1em;
  color: rgb(251, 226, 135);
}

.icon-id-card .path5:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(178, 178, 178);
}

.icon-id-card .path6:before {
  content: "\eb23";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}

.icon-id-card .path7:before {
  content: "\eb24";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}

.icon-id-card .path8:before {
  content: "\eb25";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-id-card .path9:before {
  content: "\eb26";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-id-card .path10:before {
  content: "\eb27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-id-card .path11:before {
  content: "\eb28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-id-card .path12:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}

.icon-id-card .path13:before {
  content: "\eb2a";
  margin-left: -1em;
  color: rgb(247, 237, 188);
}

.icon-id-card .path14:before {
  content: "\eb2b";
  margin-left: -1em;
  color: rgb(77, 104, 181);
}

.icon-id-card .path15:before {
  content: "\eb2c";
  margin-left: -1em;
  color: rgb(74, 117, 195);
}

.icon-id-card .path16:before {
  content: "\eb2d";
  margin-left: -1em;
  color: rgb(69, 143, 222);
}

.icon-id-card .path17:before {
  content: "\eb2e";
  margin-left: -1em;
  color: rgb(111, 174, 232);
}

.icon-id-card .path18:before {
  content: "\eb2f";
  margin-left: -1em;
  color: rgb(69, 143, 222);
}

.icon-id-card .path19:before {
  content: "\eb30";
  margin-left: -1em;
  color: rgb(111, 174, 232);
}

.icon-seller:before {
  content: "\ea8d";
}

.icon-storehouse .path1:before {
  content: "\ea8f";
  color: rgb(255, 152, 0);
}

.icon-storehouse .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(212, 126, 0);
}

.icon-storehouse .path3:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(212, 126, 0);
}

.icon-storehouse .path4:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}

.icon-storehouse .path5:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}

.icon-storehouse .path6:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}

.icon-storehouse .path7:before {
  content: "\ea97";
  margin-left: -1em;
  color: rgb(138, 81, 0);
}

.icon-storehouse .path8:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}

.icon-usergroup .path1:before {
  content: "\ea9b";
  color: rgb(139, 195, 74);
}

.icon-usergroup .path2:before {
  content: "\eb36";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}

.icon-usergroup .path3:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(255, 167, 38);
}

.icon-usergroup .path4:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(255, 167, 38);
}

.icon-usergroup .path5:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(51, 105, 30);
}

.icon-usergroup .path6:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(51, 105, 30);
}

.icon-usergroup .path7:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}

.icon-usergroup .path8:before {
  content: "\eb3c";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}

.icon-usergroup .path9:before {
  content: "\eb3d";
  margin-left: -1em;
  color: rgb(255, 183, 77);
}

.icon-usergroup .path10:before {
  content: "\eb3e";
  margin-left: -1em;
  color: rgb(104, 159, 56);
}

.icon-warehouse .path1:before {
  content: "\eaa4";
  color: rgb(207, 158, 118);
}

.icon-warehouse .path2:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(211, 160, 108);
}

.icon-warehouse .path3:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(230, 233, 237);
}

.icon-warehouse .path4:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(236, 193, 156);
}

.icon-warehouse .path5:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(255, 130, 110);
}

.icon-warehouse .path6:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(252, 110, 81);
}

.icon-warehouse .path7:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(105, 214, 244);
}

.icon-warehouse .path8:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}

.icon-warehouse .path9:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}

.icon-warehouse .path10:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}

.icon-warehouse .path11:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(170, 178, 189);
}

.icon-warehouse .path12:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(150, 159, 170);
}

.icon-warehouse .path13:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(150, 159, 170);
}

.icon-warehouse .path14:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(211, 160, 108);
}

.icon-warehouse .path15:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-warehouse .path16:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-warehouse .path17:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-almacen .path1:before {
  content: "\eab5";
  color: rgb(141, 150, 158);
}

.icon-almacen .path2:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(172, 181, 188);
}

.icon-almacen .path3:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(214, 9, 73);
}

.icon-almacen .path4:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(183, 2, 67);
}

.icon-almacen .path5:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}

.icon-almacen .path6:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}

.icon-almacen .path7:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}

.icon-almacen .path8:before {
  content: "\eabc";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}

.icon-almacen .path9:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}

.icon-almacen .path10:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}

.icon-almacen .path11:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(193, 126, 82);
}

.icon-almacen .path12:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(181, 104, 65);
}

.icon-almacen .path13:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(168, 103, 67);
}

.icon-zip .path1:before {
  content: "\eac2";
  color: rgb(255, 145, 0);
}

.icon-zip .path2:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-zip .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-zip .path4:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-zip .path5:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-add .path1:before {
  content: "\e901";
  color: rgb(76, 175, 80);
}

.icon-add .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-add .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-addressbook .path1:before {
  content: "\e904";
  color: rgb(103, 58, 183);
}

.icon-addressbook .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(49, 27, 146);
}

.icon-addressbook .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-approval .path1:before {
  content: "\e907";
  color: rgb(139, 195, 74);
}

.icon-approval .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(204, 255, 144);
}

.icon-business:before {
  content: "\e90c";
}

.icon-cancel .path1:before {
  content: "\e90d";
  color: rgb(244, 67, 54);
}

.icon-cancel .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-cancel .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-category .path1:before {
  content: "\e956";
  color: rgb(144, 202, 249);
}

.icon-category .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-chart-line:before {
  content: "\e959";
}

.icon-check .path1:before {
  content: "\e957";
  color: rgb(149, 117, 205);
}

.icon-check .path2:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-check .path3:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path4:before {
  content: "\eb42";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path5:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path6:before {
  content: "\eb44";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path7:before {
  content: "\eb45";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path8:before {
  content: "\eb46";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path9:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-check .path10:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-company .path1:before {
  content: "\e95d";
  color: rgb(30, 136, 229);
}

.icon-company .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-company .path3:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}

.icon-company .path4:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}

.icon-company .path5:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(13, 71, 161);
}

.icon-company .path6:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}

.icon-company .path7:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-company .path8:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}

.icon-company .path9:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path10:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path11:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path12:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path13:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path14:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path15:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path16:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path17:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path18:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path19:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-company .path20:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(21, 101, 192);
}

.icon-config .path1:before {
  content: "\e977";
  color: rgb(230, 81, 0);
}

.icon-config .path2:before {
  content: "\eb49";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}

.icon-excel .path1:before {
  content: "\e990";
  color: rgb(76, 175, 80);
}

.icon-excel .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path7:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path8:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path9:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-excel .path10:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(46, 125, 50);
}

.icon-excel .path11:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-gas .path1:before {
  content: "\e9a2";
  color: rgb(66, 165, 245);
}

.icon-gas .path2:before {
  content: "\eb4a";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}

.icon-gas .path3:before {
  content: "\eb4b";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-gas .path4:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}

.icon-gas .path5:before {
  content: "\eb4d";
  margin-left: -1em;
  color: rgb(178, 235, 242);
}

.icon-gas .path6:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}

.icon-gas .path7:before {
  content: "\eb4f";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}

.icon-gas .path8:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}

.icon-help .path1:before {
  content: "\e9b5";
  color: rgb(33, 150, 243);
}

.icon-help .path2:before {
  content: "\eb51";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}

.icon-help .path3:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}

.icon-info .path1:before {
  content: "\e9b7";
  color: rgb(33, 150, 243);
}

.icon-info .path2:before {
  content: "\eb53";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-info .path3:before {
  content: "\eb54";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-logout .path1:before {
  content: "\e9c8";
  color: rgb(244, 67, 54);
}

.icon-logout .path2:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 205, 210);
}

.icon-logout .path3:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 205, 210);
}

.icon-order .path1:before {
  content: "\e9d2";
  color: rgb(197, 202, 233);
}

.icon-order .path2:before {
  content: "\eb55";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path3:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path4:before {
  content: "\eb57";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path5:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path6:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path7:before {
  content: "\eb5a";
  margin-left: -1em;
  color: rgb(121, 134, 203);
}

.icon-order .path8:before {
  content: "\eb5b";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}

.icon-orderhistory .path1:before {
  content: "\e9da";
  color: rgb(144, 202, 249);
}

.icon-orderhistory .path2:before {
  content: "\eb5c";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path3:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path4:before {
  content: "\eb5e";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path5:before {
  content: "\eb5f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path6:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(115, 188, 247);
}

.icon-orderhistory .path7:before {
  content: "\eb61";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path8:before {
  content: "\eb62";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-orderhistory .path9:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}

.icon-orderhistory .path10:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}

.icon-orderhistory .path11:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(255, 224, 178);
}

.icon-orderhistory .path12:before {
  content: "\eb66";
  margin-left: -1em;
  color: rgb(141, 110, 99);
}

.icon-orderhistory .path13:before {
  content: "\eb67";
  margin-left: -1em;
  color: rgb(109, 76, 65);
}

.icon-orderhistory .path14:before {
  content: "\eb68";
  margin-left: -1em;
  color: rgb(251, 140, 0);
}

.icon-pdf .path1:before {
  content: "\e9ec";
  color: rgb(231, 76, 60);
}

.icon-pdf .path2:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(251, 233, 231);
}

.icon-pdf .path3:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-previous:before {
  content: "\e9f6";
  color: #2196f3;
}

.icon-price .path1:before {
  content: "\e9f7";
  color: rgb(63, 81, 181);
}

.icon-price .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(255, 245, 157);
}

.icon-pricing .path1:before {
  content: "\e9f9";
  color: rgb(76, 175, 80);
}

.icon-pricing .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-printing .path1:before {
  content: "\e9fb";
  color: rgb(97, 97, 97);
}

.icon-printing .path2:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}

.icon-printing .path3:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}

.icon-printing .path4:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(0, 230, 118);
}

.icon-printing .path5:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(36, 36, 36);
}

.icon-printing .path6:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-printing .path7:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-printing .path8:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}

.icon-printing .path9:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-printing .path10:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-product .path1:before {
  content: "\ea05";
  color: rgb(255, 152, 0);
}

.icon-product .path2:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}

.icon-product .path3:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}

.icon-product .path4:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}

.icon-product .path5:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}

.icon-product .path6:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(117, 70, 0);
}

.icon-product .path7:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 170, 46);
}

.icon-receipt .path1:before {
  content: "\ea0e";
  color: rgb(26, 188, 156);
}

.icon-receipt .path2:before {
  content: "\eb69";
  margin-left: -1em;
  color: rgb(232, 234, 246);
}

.icon-receipt .path3:before {
  content: "\eb6a";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path4:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path5:before {
  content: "\eb6c";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path6:before {
  content: "\eb6d";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path7:before {
  content: "\eb6e";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path8:before {
  content: "\eb6f";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path9:before {
  content: "\eb70";
  margin-left: -1em;
  color: rgb(159, 168, 218);
}

.icon-receipt .path10:before {
  content: "\eb71";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}

.icon-receipt .path11:before {
  content: "\eb72";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}

.icon-saveall .path1:before {
  content: "\ea1e";
  color: rgb(2, 119, 189);
}

.icon-saveall .path2:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(144, 164, 174);
}

.icon-saveall .path3:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}

.icon-saveall .path4:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(52, 152, 219);
}

.icon-saveall .path5:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-saveall .path6:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}

.icon-saveall .path7:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}

.icon-saveall .path8:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}

.icon-saveall .path9:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(41, 128, 185);
}

.icon-saveall .path10:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}

.icon-saveall .path11:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(69, 90, 100);
}

.icon-saveall .path12:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(1, 87, 155);
}

.icon-saveall .path13:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(120, 144, 156);
}

.icon-saveall .path14:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(38, 50, 56);
}

.icon-send2print .path1:before {
  content: "\ea2d";
  color: rgb(97, 97, 97);
}

.icon-send2print .path2:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}

.icon-send2print .path3:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(66, 66, 66);
}

.icon-send2print .path4:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(0, 230, 118);
}

.icon-send2print .path5:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(36, 36, 36);
}

.icon-send2print .path6:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-send2print .path7:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(144, 202, 249);
}

.icon-send2print .path8:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(66, 165, 245);
}

.icon-send2print .path9:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-send2print .path10:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-send2print .path11:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-send2print .path12:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-service .path1:before {
  content: "\ea48";
  color: rgb(76, 175, 80);
}

.icon-service .path2:before {
  content: "\eb73";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-service .path3:before {
  content: "\eb74";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-service .path4:before {
  content: "\eb75";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-service .path5:before {
  content: "\eb76";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-service .path6:before {
  content: "\eb77";
  margin-left: -1em;
  color: rgb(27, 94, 32);
}

.icon-service .path7:before {
  content: "\eb78";
  margin-left: -1em;
  color: rgb(129, 199, 132);
}

.icon-sidemenu .path1:before {
  content: "\ea47";
  color: rgb(187, 222, 251);
}

.icon-sidemenu .path2:before {
  content: "\eb79";
  margin-left: -1em;
  color: rgb(63, 81, 181);
}

.icon-sidemenu .path3:before {
  content: "\eb7a";
  margin-left: -1em;
  color: rgb(255, 235, 59);
}

.icon-sidemenu .path4:before {
  content: "\eb7b";
  margin-left: -1em;
  color: rgb(139, 195, 74);
}

.icon-sidemenu .path5:before {
  content: "\eb7c";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}

.icon-store .path1:before {
  content: "\ea49";
  color: rgb(207, 216, 220);
}

.icon-store .path2:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}

.icon-store .path3:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}

.icon-store .path4:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}

.icon-store .path5:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(100, 181, 246);
}

.icon-store .path6:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}

.icon-store .path7:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}

.icon-store .path8:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(216, 67, 21);
}

.icon-store .path9:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}

.icon-store .path10:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}

.icon-store .path11:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}

.icon-store .path12:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(255, 87, 34);
}

.icon-store .path13:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}

.icon-store .path14:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}

.icon-store .path15:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}

.icon-store .path16:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(255, 160, 0);
}

.icon-store .path17:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}

.icon-store .path18:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}

.icon-store .path19:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}

.icon-store .path20:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}

.icon-store .path21:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}

.icon-store .path22:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}

.icon-store .path23:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}

.icon-store .path24:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(30, 136, 229);
}

.icon-tag:before {
  content: "\ea78";
  color: #f35517;
}

.icon-widget:before {
  content: "\ea9a";
}

.icon-food:before {
  content: "\e900";
}

.icon-printer-bk:before {
  content: "\e909";
}

.icon-document-edit:before {
  content: "\e90a";
}

.icon-document-certificate:before {
  content: "\e91e";
}

.icon-certificate:before {
  content: "\e91f";
}

.icon-certificate-bg:before {
  content: "\e924";
}

.icon-box:before {
  content: "\e925";
}

.icon-award:before {
  content: "\e910";
}

.icon-award-bk:before {
  content: "\e911";
}

.icon-pie-chart:before {
  content: "\e912";
}

.icon-medal:before {
  content: "\e913";
}

.icon-medal-bg:before {
  content: "\e914";
}

.icon-locked:before {
  content: "\e915";
}

.icon-unlocked:before {
  content: "\e916";
}

.icon-box-open:before {
  content: "\e917";
}

.icon-box2:before {
  content: "\e918";
}

.icon-coins:before {
  content: "\e919";
}

.icon-music:before {
  content: "\e91a";
}

.icon-male:before {
  content: "\e91b";
}

.icon-female:before {
  content: "\e91c";
}

.icon-aids:before {
  content: "\e91d";
}

.icon-bus:before {
  content: "\e920";
}

.icon-bike1:before {
  content: "\e921";
}

.icon-plane:before {
  content: "\e922";
}

.icon-globe:before {
  content: "\e923";
}

.icon-assignment_ind:before {
  content: "\e95c";
}

.icon-translate:before {
  content: "\e95b";
}

.icon-checkroom:before {
  content: "\e95a";
}

.icon-stats:before {
  content: "\e97b";
}

.icon-floppy:before {
  content: "\e934";
}

.icon-pause:before {
  content: "\e926";
}

.icon-stop:before {
  content: "\e927";
}

.icon-eject:before {
  content: "\e928";
}

.icon-first:before {
  content: "\e929";
}

.icon-last:before {
  content: "\e92a";
}

.icon-play-alt:before {
  content: "\e92b";
}

.icon-home:before {
  content: "\e92c";
}

.icon-edificio:before {
  content: "\e92d";
}

.icon-newspaper:before {
  content: "\e92e";
}

.icon-edit:before {
  content: "\e92f";
}

.icon-paint-format:before {
  content: "\e930";
}

.icon-image:before {
  content: "\e931";
}

.icon-images:before {
  content: "\e932";
}

.icon-camera:before {
  content: "\e933";
}

.icon-film:before {
  content: "\e935";
}

.icon-wifi:before {
  content: "\e936";
}

.icon-mic:before {
  content: "\e937";
}

.icon-book:before {
  content: "\e938";
}

.icon-books:before {
  content: "\e939";
}

.icon-library:before {
  content: "\e93a";
}

.icon-profile:before {
  content: "\e93b";
}

.icon-copy:before {
  content: "\e93c";
}

.icon-paste:before {
  content: "\e93d";
}

.icon-folder:before {
  content: "\e93e";
}

.icon-folder-open:before {
  content: "\e93f";
}

.icon-folder-plus:before {
  content: "\e940";
}

.icon-folder-minus:before {
  content: "\e941";
}

.icon-folder-download:before {
  content: "\e942";
}

.icon-folder-upload:before {
  content: "\e943";
}

.icon-price-tag:before {
  content: "\e944";
}

.icon-price-tags:before {
  content: "\e945";
}

.icon-barcode:before {
  content: "\e946";
}

.icon-qrcode:before {
  content: "\e947";
}

.icon-cart:before {
  content: "\e948";
}

.icon-calculator:before {
  content: "\e949";
}

.icon-phone:before {
  content: "\e94a";
}

.icon-address-book:before {
  content: "\e94b";
}

.icon-pin:before {
  content: "\e94c";
}

.icon-pinpoint:before {
  content: "\e94d";
}

.icon-map:before {
  content: "\e94e";
}

.icon-bk-history:before {
  content: "\e94f";
}

.icon-alarm:before {
  content: "\e950";
}

.icon-stopwatch:before {
  content: "\e952";
}

.icon-calendar:before {
  content: "\e953";
}

.icon-printer:before {
  content: "\e954";
}

.icon-keyboard:before {
  content: "\e955";
}

.icon-mobile:before {
  content: "\e958";
}

.icon-undo:before {
  content: "\e967";
}

.icon-redo:before {
  content: "\e968";
}

.icon-bubbles:before {
  content: "\e96c";
}

.icon-user:before {
  content: "\e971";
}

.icon-users:before {
  content: "\e972";
}

.icon-user-plus:before {
  content: "\e973";
}

.icon-user-minus:before {
  content: "\e974";
}

.icon-user-check:before {
  content: "\e975";
}

.icon-user-tie:before {
  content: "\e976";
}

.icon-binoculars:before {
  content: "\e985";
}

.icon-search:before {
  content: "\e986";
}

.icon-zoom-in:before {
  content: "\e987";
}

.icon-zoom-out:before {
  content: "\e988";
}

.icon-enlarge:before {
  content: "\e989";
}

.icon-shrink:before {
  content: "\e98a";
}

.icon-key:before {
  content: "\e98d";
}

.icon-key2:before {
  content: "\e98e";
}

.icon-equalizer:before {
  content: "\e993";
}

.icon-cog:before {
  content: "\e994";
}

.icon-cogs:before {
  content: "\e995";
}

.icon-hammer:before {
  content: "\e996";
}

.icon-pie-chart-b:before {
  content: "\e99a";
}

.icon-stats-dots:before {
  content: "\e99b";
}

.icon-stats-bars:before {
  content: "\e99d";
}

.icon-glass:before {
  content: "\e9a4";
}

.icon-spoon-knife:before {
  content: "\e9a3";
}

.icon-meter:before {
  content: "\e9a6";
}

.icon-bin:before {
  content: "\e9ac";
}

.icon-truck:before {
  content: "\e9b0";
}

.icon-target:before {
  content: "\e9b3";
}

.icon-shield:before {
  content: "\e9b4";
}

.icon-switch:before {
  content: "\e9b6";
}

.icon-clipboard:before {
  content: "\e9b8";
}

.icon-tree:before {
  content: "\e9bc";
}

.icon-cloud-download:before {
  content: "\e9c2";
}

.icon-cloud-upload:before {
  content: "\e9c3";
}

.icon-earth:before {
  content: "\e9ca";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-flag:before {
  content: "\e9cc";
}

.icon-attachment:before {
  content: "\e9cd";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-eye-blocked:before {
  content: "\e9d1";
}

.icon-bookmark:before {
  content: "\e9d4";
}

.icon-bookmarks:before {
  content: "\e9d3";
}

.icon-star-empty:before {
  content: "\e9d7";
}

.icon-star-half:before {
  content: "\e9d8";
}

.icon-star-full:before {
  content: "\e9d9";
}

.icon-warning:before {
  content: "\ea19";
}

.icon-notification:before {
  content: "\ea18";
}

.icon-question:before {
  content: "\ea17";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-minus:before {
  content: "\ea0b";
}

.icon-info1:before {
  content: "\ea16";
}

.icon-cancel-circle:before {
  content: "\ea15";
}

.icon-blocked:before {
  content: "\ea12";
}

.icon-cross:before {
  content: "\ea0f";
}

.icon-checkmark:before {
  content: "\ea10";
}

.icon-checkmark2:before {
  content: "\ea11";
}

.icon-enter:before {
  content: "\ea13";
}

.icon-exit:before {
  content: "\ea14";
}

.icon-infinite:before {
  content: "\ea2f";
}

.icon-shuffle:before {
  content: "\ea30";
}

.icon-arrow-up-left:before {
  content: "\ea31";
}

.icon-arrow-up:before {
  content: "\ea32";
}

.icon-arrow-up-right:before {
  content: "\ea33";
}

.icon-arrow-right:before {
  content: "\ea34";
}

.icon-arrow-down-right:before {
  content: "\ea35";
}

.icon-arrow-down:before {
  content: "\ea36";
}

.icon-arrow-down-left:before {
  content: "\ea37";
}

.icon-arrow-left:before {
  content: "\ea38";
}

.icon-circle-up:before {
  content: "\ea41";
}

.icon-circle-right:before {
  content: "\ea42";
}

.icon-circle-down:before {
  content: "\ea43";
}

.icon-circle-left:before {
  content: "\ea44";
}

.icon-sort-amount-asc:before {
  content: "\ea4c";
}

.icon-sort-amount-desc:before {
  content: "\ea4d";
}

.icon-check:before,
.icon-checked:before {
  content: "\ea52";
}

.icon-uncheck:before {
  content: "\ea53";
}

.icon-radio-checked:before {
  content: "\ea54";
}

.icon-radio-checked-bg:before {
  content: "\ea55";
}

.icon-radio-unchecked:before {
  content: "\ea56";
}

.icon-crop:before {
  content: "\ea57";
}

.icon-ungroup:before {
  content: "\ea59";
}

.icon-scissors:before {
  content: "\ea5a";
}

.icon-filter:before {
  content: "\ea5b";
}

.icon-text-height:before {
  content: "\ea5f";
}

.icon-text-width:before {
  content: "\ea60";
}

.icon-font-size:before {
  content: "\ea61";
}

.icon-bold:before {
  content: "\ea62";
}

.icon-underline:before {
  content: "\ea63";
}

.icon-italic:before {
  content: "\ea64";
}

.icon-strikethrough:before {
  content: "\ea65";
}

.icon-sigma:before {
  content: "\ea67";
}

.icon-superscript:before {
  content: "\ea69";
}

.icon-subscript:before {
  content: "\ea6a";
}

.icon-text-color:before {
  content: "\ea6d";
}

.icon-table:before {
  content: "\ea71";
}

.icon-new-tab:before {
  content: "\ea7e";
}

.icon-embed:before {
  content: "\ea7f";
}

.icon-embed2:before {
  content: "\ea80";
}

.icon-terminal:before {
  content: "\ea81";
}

.icon-share:before {
  content: "\ea82";
}

.icon-mail:before {
  content: "\ea83";
}

.icon-whatsapp:before {
  content: "\ea93";
}

.icon-telegram:before {
  content: "\ea95";
}

.icon-safari:before {
  content: "\eadd";
}

.icon-file-pdf:before {
  content: "\eadf";
}

.icon-file-word:before {
  content: "\eae1";
}

.icon-file-excel:before {
  content: "\eae2";
}

.icon-libreoffice:before {
  content: "\eae3";
}

.icon-spinner:before {
  content: "\eb1a";
}

.icon-spinner11:before {
  content: "\eb1b";
}

.icon-th-large:before {
  content: "\f009";
}

.icon-th:before {
  content: "\f00a";
}

.icon-th-list:before {
  content: "\f00b";
}

.icon-chevron-left:before {
  content: "\f053";
}

.icon-chevron-right:before {
  content: "\f054";
}

.icon-chevron-up:before {
  content: "\f077";
}

.icon-chevron-down:before {
  content: "\f078";
}

.icon-bell-o:before {
  content: "\f0a2";
}

.icon-group:before {
  content: "\f0c0";
}

.icon-users1:before {
  content: "\f0c0";
}

.icon-floppy-o:before {
  content: "\f0c7";
}

.icon-save:before {
  content: "\f0c7";
}

.icon-medkit:before {
  content: "\f0fa";
}

.icon-thumbs-up:before {
  content: "\f164";
}

.icon-thumbs-down:before {
  content: "\f165";
}

.icon-wheelchair:before {
  content: "\f193";
}

.icon-graduation-cap:before {
  content: "\f19d";
}

.icon-mortar-board:before {
  content: "\f19d";
}

.icon-paw:before {
  content: "\f1b0";
}

.icon-database:before {
  content: "\f1c0";
}

.icon-file-pdf-o:before {
  content: "\f1c1";
}

.icon-file-word-o:before {
  content: "\f1c2";
}

.icon-file-excel-o:before {
  content: "\f1c3";
}

.icon-file-powerpoint-o:before {
  content: "\f1c4";
}

.icon-file-image-o:before {
  content: "\f1c5";
}

.icon-file-photo-o:before {
  content: "\f1c5";
}

.icon-file-picture-o:before {
  content: "\f1c5";
}

.icon-file-archive-o:before {
  content: "\f1c6";
}

.icon-file-zip-o:before {
  content: "\f1c6";
}

.icon-file-audio-o:before {
  content: "\f1c7";
}

.icon-file-sound-o:before {
  content: "\f1c7";
}

.icon-file-movie-o:before {
  content: "\f1c8";
}

.icon-file-video-o:before {
  content: "\f1c8";
}

.icon-file-code-o:before {
  content: "\f1c9";
}

.icon-toggle-off:before {
  content: "\f204";
}

.icon-toggle-on:before {
  content: "\f205";
}

.icon-calendar-plus-o:before {
  content: "\f271";
}

.icon-calendar-minus-o:before {
  content: "\f272";
}

.icon-industry:before {
  content: "\f275";
}

.icon-usb:before {
  content: "\f287";
}

.icon-bluetooth:before {
  content: "\f293";
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.bg-primary {
  background: 3a4b91;
  /* background: linear-gradient(270deg, #3490dc, #6574cd);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }
  */
}

.dropdown > .btn {
  border: none !important;
  box-shadow: none !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

body {
  font-weight: 400;
  color: #212529;
  text-align: left;
}

.modal-header {
  padding: 0.5rem 1rem 0.2rem 1rem !important;
}

.modal-body {
  padding: 0;
}

.btn,
.form-control,
.v-select,
.custom-select {
  border-color: rgba(0, 0, 0, 0.5019607843) !important;
  height: auto !important;
}

.custom-control.custom-checkbox.b-custom-control-sm {
  margin-top: 4px;
}

.dropdown-toggle {
  border: solid 1px rgba(0, 0, 0, 0.5019607843) !important;
  padding: 0 !important;
  margin-bottom: 2px;
}

.dropdown-toggle > div > input {
  border: none !important;
  margin: 0 !important;
}

.custom-radio {
  margin-bottom: 2px;
}

.dropdown.v-select.single > div > * {
  min-height: calc(1.2em + 0.36rem + 2px);
}

.dropdown.v-select.single > div > .vs__selected-options > input {
  position: absolute;
  height: calc(1.2em + 0.36rem + 2px);
  width: 100%;
}

.dropdown.v-select.single > div .open-indicator {
  position: absolute;
  right: 2px;
  top: 9px;
}

.dropdown.v-select.single > div .clear {
  margin-right: 10px !important;
  margin-top: 2px;
}

.dropdown.v-select.single > div .vs__actions {
  position: absolute;
  right: 0;
  top: -4px;
  z-index: 1;
}

.dropdown.v-select.single > div .selected-tag {
  margin-top: 0 !important;
  z-index: 9;
  width: 97%;
}

#app > div.wrapper > #content .mx-datepicker,
#app > div.wrapper > #content .custom-select,
#app > div.wrapper > #content select,
#app > div.wrapper > #content input,
#app > div.wrapper > #content .form-control {
  min-height: 24px;
  height: calc(1em + 0.75rem + 2px);
  max-width: 100% !important;
}

.v-select .vs__selected-options {
  padding: 0 !important;
}

.modal-content {
  box-shadow: 0px 0px 10px rgb(0, 0, 0) !important;
  border-radius: 0.4rem;
  /*transition: box-shadow 0.3s ease-in-out !important; */
}

.modal-body {
  padding: 5px 5px;
}

.btn {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.4rem;
}

.btn-group:not(:first-of-type) {
  margin-left: 1em;
}

.btn-group .btn {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
  border-radius: 0.4rem;
}

#content > div {
  padding-left: 10px;
  padding-right: 10px;
  border: none;
}

h1,
h2,
h3 {
  padding: 5px !important;
}

.btn-group {
  margin-bottom: 8px;
}

.container {
  max-width: 100%;
}

.vue-sticky-el.top-sticky:not(:hover):before {
  display: block;
  background: #ccc;
  content: "...";
  height: 16px;
  min-height: 15px;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  line-height: 0px;
  font-size: 24px;
  opacity: 0.7;
}

.elTable > thead {
  position: sticky;
  top: -10px;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.vue-sticky-el.top-sticky:not(:hover) {
  max-height: 16px !important;
  min-height: 15px !important;
  overflow: hidden;
}

.vue-sticky-el.top-sticky {
  border-radius: 0px 0px 10px 10px;
  /*margin:0 5px 10px 5px;*/
  outline: solid 1px #ccc;
  box-shadow: 0 0 4px;
  z-index: 999 !important;
}

.table_container {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.5019607843);
  border: solid 1px #aaa;
  border-radius: 4px;
  margin: 2px 0 2px 0;
  /* // border-radius: 10px !important; */
}

.wrapper.menuFixed {
  /*
      th{
          position: sticky !important;
      }
      */
}

.wrapper.menuFixed .table_container {
  max-height: calc(100vh - 161px);
}

.dd_escalafon .dropdown-menu {
  min-width: 450px !important;
  min-height: calc(100vh - 100px);
  box-shadow: 0 0 12px;
}

.horariowrapper0 {
  min-height: 250px;
  max-height: 250px;
}

.form-group .date-picke {
  padding: 0;
}

.tags-view-item {
  height: 75px;
  background: blue;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  transform: skew(-20deg);
  border-radius: 0 !important;
  margin: -5px;
}

.vue-sticky-el {
  background: #fff;
  z-index: 99;
  min-height: 34px;
  /*
    max-height:80px !important;
      overflow-y: auto;
      overflow-x: hidden;
  */
}

.vue-sticky-el.top-sticky {
  max-height: 300px !important;
}

.input-group-prepend > button {
  width: 100%;
}

.vue-monthly-picker .month-picker-wrapper {
  min-width: 60px;
  max-width: 90px;
}

#data > div > h4,
#data > h4 {
  display: none;
}

element.style {
  display: block;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.alert-danger {
  background-color: rgba(249, 214, 213, 0.6666666667);
}

.alert-warning {
  background-color: rgba(255, 251, 219, 0.6666666667);
}

.alert-success {
  background-color: rgba(215, 243, 227, 0.6666666667);
}

.bioCC {
  height: 170px;
  overflow: auto;
  margin-bottom: 5px;
  border: solid 1px #aaa;
  border-radius: 4px;
}

.bioc {
  text-align: left;
  width: 100%;
}

.bioc .bio {
  float: right;
}

.bio > * {
  width: 16px !important;
  height: auto !important;
  display: inline-block;
  border: none;
}

.bio > img.disabled {
  opacity: 0.05 !important;
}

button.bioc > span {
  display: block;
  width: 100%;
}

button.bioc.btn {
  border: none;
  box-shadow: none !important;
}

.modal-body > .container {
  height: 100% !important;
}

tr.RECHAZADO {
  background: #ead4d4;
}

tr.ACEPTADO {
  background: #9fa6d2;
}

tr.FP {
  opacity: 0.5;
}

.dropdown-toggle {
  margin: 0 !important;
}

.form-control > .form-control {
  border: none !important;
}

.obligatorio {
  background: rgba(255, 255, 0, 0.5215686275);
  /* border-radius: 10px; */
  border: solid #fff;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.btn-toolbar {
  max-width: 100% !important;
}

.tdcheck > i.icon {
  font-size: 21px;
  margin-left: -4px;
}

.tdcheck > i.icon.icon-uncheck {
  opacity: 0.5;
}

span.vplaceholder {
  position: absolute;
  z-index: 2;
  top: 5px;
  /* line-height: 0; */
  margin-left: 8px;
  opacity: 0.5;
  pointer-events: none;
  white-space: nowrap;
  width: calc(100% - 56px);
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  height: 100%;
}

.row > .col {
  padding-left: 2px;
  padding-right: 2px;
}

div,
span,
a {
  text-transform: uppercase;
}

.no_uc,
.no_uc * {
  text-transform: none;
}

.input-group .dropdown-toggle {
  border: none !important;
}

td,
th {
  border-top: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.5215686275);
}

.modal-dialog.modal-xl.modal-dialog-centered.modal-dialog-scrollable {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
}

#editperson {
  min-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#editperson > .ui-field-contain {
  width: 30% !important;
  margin: 0;
  padding: 0;
}

.pagination > .total {
  position: absolute;
  right: 0;
}

.pagination > .page {
  white-space: nowrap;
  text-align: center;
}

.custom-control-label {
  margin-top: 4px;
}

td {
  position: relative;
}

td > div.activo {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  grackground: rgb(240, 252, 240);
}

.nuevo > *,
.editable > * {
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: solid 1px #000;
  overflow: auto;
  background: #fff;
  z-index: 4;
}

.nuevo:after,
.editable:after {
  position: fixed;
  display: block;
  content: " ";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

[disabled] {
  filter: opacity(0.5) saturate(0);
  pointer-events: none;
}

